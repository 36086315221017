// src/components/DropdownWithCheckbox.jsx

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './DropdownWithCheckbox.css'; // Asegúrate de tener los estilos adecuados

const DropdownWithCheckbox = ({
  label,
  options,
  selectedOptions,
  setSelectedOptions,
  isMultiSelect,
  readOnly = false, // Prop para modo de solo lectura
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Manejar clic fuera del dropdown para cerrarlo
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Manejar selección de opciones
  const handleOptionChange = (optionId) => {
    if (readOnly) return; // Evitar cambios si es solo lectura

    if (isMultiSelect) {
      if (selectedOptions.includes(optionId)) {
        setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
      } else {
        setSelectedOptions([...selectedOptions, optionId]);
      }
    } else {
      if (selectedOptions.includes(optionId)) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions([optionId]);
      }
      setIsOpen(false); // Cerrar dropdown si es single-select
    }
  };

  // Obtener etiquetas de las opciones seleccionadas
  const getSelectedLabels = () => {
    const selected = options.filter((option) =>
      selectedOptions.includes(option.id)
    );
    if (readOnly) {
      return selected.length > 0
        ? selected.map((option) => option.nombre).join(', ')
        : 'Sin categorías seleccionadas';
    }
    if (selected.length === 0) return 'Selecciona una opción';
    if (isMultiSelect) return selected.map((option) => option.nombre).join(', ');
    return selected[0].nombre;
  };

  // Manejar teclas para accesibilidad
  const handleKeyDown = (event) => {
    if (readOnly) return;
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      setIsOpen(!isOpen);
    }
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };



  return (
    <div className="dropdown-container" ref={dropdownRef}>
      {label && <label className="dropdown-label">{label}:</label>}
      <div
        className={`dropdown-header ${readOnly ? 'read-only' : ''}`}
        onClick={() => !readOnly && setIsOpen(!isOpen)}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <span>{getSelectedLabels()}</span>
        {!readOnly && (
          <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}></span>
        )}
      </div>
      {isOpen && !readOnly && (
        <ul className="dropdown-list" role="listbox">
          {options.length > 0 ? (
            options.map((option) => (
              <li
                key={`dropdown-option-${option.id}`}
                className="dropdown-item"
                role={isMultiSelect ? 'option' : 'option'}
                aria-selected={selectedOptions.includes(option.id)}
                onClick={() => handleOptionChange(option.id)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    handleOptionChange(option.id);
                  }
                }}
                tabIndex={0}
              >
                <input
                  type={isMultiSelect ? 'checkbox' : 'radio'}
                  checked={selectedOptions.includes(option.id)}
                  onChange={() => handleOptionChange(option.id)}
                  tabIndex={-1} // Evitar que el input reciba foco directamente
                />
                <span>{option.nombre}</span>
              </li>
            ))
          ) : (
            <li className="dropdown-item">Sin opciones disponibles</li>
          )}
        </ul>
      )}
      {isOpen && readOnly && (
        <ul className="dropdown-list read-only-list" role="listbox">
          {options.length > 0 ? (
            options.map((option) => (
              <li
                key={`dropdown-readonly-${option.id}`}
                className="dropdown-item read-only"
                role="option"
                aria-selected={selectedOptions.includes(option.id)}
              >
                <span>{option.nombre}</span>
              </li>
            ))
          ) : (
            <li className="dropdown-item read-only">Sin categorías</li>
          )}
        </ul>
      )}
    </div>
  );
};

// Definición de las props y sus tipos
DropdownWithCheckbox.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired, // ID de la categoría
      nombre: PropTypes.string.isRequired, // Nombre de la categoría
    })
  ).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedOptions: PropTypes.func.isRequired, // Función para manejar cambios
  isMultiSelect: PropTypes.bool.isRequired, // Indica si es multi-select
  readOnly: PropTypes.bool, // Indica si es solo lectura
};

export default DropdownWithCheckbox;
