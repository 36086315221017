// src/App.js
import React from 'react';
import NavbarMain from './components/Navbar/NavbarMain';
import { AuthProvider } from './contexts/AuthContext';
import AppRoutes from './AppRoutes';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Footer from './components/Footer/Footer'; // Importar Footer

const App = () => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <NavbarMain />
        <div className="container">
          <AppRoutes />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          pauseOnHover
        />
        <Footer /> {/* Agregar el Footer */}
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;
