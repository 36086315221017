import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode'; 

// Crear el contexto de autenticación
const AuthContext = createContext();

// Hook personalizado para usar el contexto de autenticación
export const useAuth = () => useContext(AuthContext);

// Proveedor de autenticación
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); 
  const [user, setUser] = useState(null);

  // Función para cerrar sesión
  const logout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    setUser(null);
  }, []); // useCallback asegura que la función se mantenga estable entre renderizados

  // Función para verificar la expiración del token
  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Tiempo actual en segundos
      return decoded.exp < currentTime;
    } catch (error) {
      console.error('Error al decodificar el token', error);
      return true; // Si no se puede decodificar el token, lo tratamos como expirado
    }
  };

  // Función para iniciar sesión
  const login = (data) => {
    try {
      // Eliminar el token anterior antes de iniciar sesión
      localStorage.removeItem('token');
      localStorage.removeItem('user');

      // Guardar el nuevo token e información del usuario
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));

      setUser(data.user);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Error guardando datos en localStorage', error);
    }
  };

  // Verificar el token y el usuario en localStorage cuando la aplicación se monta
  useEffect(() => {
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('user');

    if (token && userData && userData !== 'undefined') {
      if (isTokenExpired(token)) {
        logout(); // Si el token ha expirado, cerramos sesión
      } else {
        try {
          const parsedUser = JSON.parse(userData);
          if (parsedUser && typeof parsedUser === 'object') {
            setIsAuthenticated(true);
            setUser(parsedUser);
          } else {
            logout();
          }
        } catch (error) {
          logout();
        }
      }
    } else {
      setIsAuthenticated(false); // Si no hay token o usuario, no está autenticado
    }
  }, [logout]); 

  // Escuchar cambios en el almacenamiento local (por ejemplo, si otra pestaña cierra sesión)
  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem('token');
      const userData = localStorage.getItem('user');

      if (!token || !userData || isTokenExpired(token)) {
        logout();
      } else {
        try {
          const parsedUser = JSON.parse(userData);
          setUser(parsedUser);
          setIsAuthenticated(true);
        } catch (error) {
          logout();
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [logout]); 

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
