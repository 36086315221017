// src/hooks/useTurnos.js

import { useState, useCallback } from 'react';
import {
  fetchTurnos,
  abrirTurno,
  cerrarTurno,
  cambiarEmpleado
} from '../components/Servicios/reservasService';
import { toast } from 'react-toastify';

export function useTurnos(puesto) {
  const [turnoAbierto, setTurnoAbierto] = useState(null);

  // Obtener todos los turnos y detectar si hay uno abierto
  const fetchTurnosData = useCallback(async () => {
    try {
      const data = await fetchTurnos(puesto.id); // GET /puestos/:id/turnos
      const turno = data.find((t) => t.estado === 'abierto');
      setTurnoAbierto(turno || null);
    } catch (error) {
      console.error('Error al obtener los turnos:', error);
      toast.error('Error al obtener los turnos.');
    }
  }, [puesto.id]);

  // Abrir turno: usamos la respuesta para setear turnoAbierto (empleado, etc.)
  const handleAbrirTurno = useCallback(async (empleadoId) => {
    if (turnoAbierto) {
      toast.error('Ya hay un turno abierto para este puesto.');
      return;
    }
    if (!empleadoId) {
      toast.error('Selecciona un empleado para abrir un turno.');
      return;
    }
    try {
      // Llamamos a /puestos/:id/turnos/abrir
      const turnoCreado = await abrirTurno(puesto.id, { empleadoId });
      // turnoCreado debe traer algo como: { message, turno_id, puestoId, empleadoId }
      setTurnoAbierto({
        id: turnoCreado.turno_id,
        empleado_id: turnoCreado.empleadoId,
        estado: 'abierto'
      });
      toast.success('Turno abierto exitosamente.');
      await fetchTurnosData();
    } catch (error) {
      console.error('Error al abrir el turno:', error);
      toast.error('No se pudo abrir el turno. Intente más tarde.');
    }
  }, [puesto.id, turnoAbierto, fetchTurnosData]);

  // Cerrar turno: si existe, llamamos a /puestos/:id/turnos/:turnoId/cerrar
  const handleCerrarTurno = useCallback(async () => {
    if (!turnoAbierto) {
      toast.error('No hay un turno abierto para cerrar.');
      return;
    }
    try {
      // Añadir datos adicionales si el backend lo requiere
      const data = { motivo: 'Finalización manual' }; // Ejemplo de dato adicional
      await cerrarTurno(puesto.id, turnoAbierto.id, data);
      setTurnoAbierto(null);
      toast.success('Turno cerrado exitosamente.');
      await fetchTurnosData();
    } catch (error) {
      console.error('Error al cerrar el turno:', error);
      toast.error('No se pudo cerrar el turno. Intente más tarde.');
    }
  }, [turnoAbierto, puesto.id, fetchTurnosData]);

  // Cambiar empleado en un turno abierto
  const handleCambiarEmpleado = useCallback(async (nuevoEmpleadoId, turnoId) => {
    if (!turnoAbierto) {
      toast.error('No hay un turno abierto para cambiar de empleado.');
      return;
    }
    if (!nuevoEmpleadoId) {
      toast.error('Selecciona un nuevo empleado para el turno.');
      return;
    }
    try {
      await cambiarEmpleado(puesto.id, turnoId, { nuevoEmpleadoId });
      toast.success('Empleado cambiado exitosamente en el turno.');
      await fetchTurnosData();
    } catch (error) {
      console.error('Error al cambiar empleado en el turno:', error);
      toast.error('No se pudo cambiar de empleado. Intente más tarde.');
    }
  }, [puesto.id, turnoAbierto, fetchTurnosData]);

  return {
    turnoAbierto,
    fetchTurnosData,
    handleAbrirTurno,
    handleCerrarTurno,
    handleCambiarEmpleado
  };
}
  