// src/Servicios/empleadosService.js

import { apiFetch } from './api';

/**
 * Obtener todos los empleados.
 * @returns {Promise<Array>} - Lista de empleados.
 */
export const fetchEmpleados = async () => {
  try {
    console.log('empleadosService: Obteniendo empleados...');
    const empleados = await apiFetch('empleados', {
      method: 'GET',
    });
    console.log('empleadosService: Empleados obtenidos:', empleados);
    return empleados;
  } catch (error) {
    console.error('empleadosService: Error al obtener empleados:', error);
    throw error;
  }
};

/**
 * Crear un nuevo empleado.
 * @param {Object} empleadoData - Datos del nuevo empleado.
 * @returns {Promise<Object>} - Empleado creado.
 */
export const createEmpleado = async (empleadoData) => {
  try {
    console.log('empleadosService: Creando nuevo empleado con datos:', empleadoData);
    const nuevoEmpleado = await apiFetch('empleados', {
      method: 'POST',
      body: JSON.stringify(empleadoData),
    });
    console.log('empleadosService: Empleado creado:', nuevoEmpleado);
    return nuevoEmpleado;
  } catch (error) {
    console.error('empleadosService: Error al crear empleado:', error);
    throw error;
  }
};

/**
 * Actualizar un empleado existente.
 * @param {number|string} empleadoId - ID del empleado a actualizar.
 * @param {Object} empleadoData - Datos actualizados del empleado.
 * @returns {Promise<Object>} - Empleado actualizado.
 */
export const updateEmpleado = async (empleadoId, empleadoData) => {
  try {
    console.log(`empleadosService: Actualizando empleado ID ${empleadoId} con datos:`, empleadoData);
    const empleadoActualizado = await apiFetch(`empleados/${empleadoId}`, {
      method: 'PUT',
      body: JSON.stringify(empleadoData),
    });
    console.log(`empleadosService: Empleado ID ${empleadoId} actualizado:`, empleadoActualizado);
    return empleadoActualizado;
  } catch (error) {
    console.error(`empleadosService: Error al actualizar empleado ID ${empleadoId}:`, error);
    throw error;
  }
};

/**
 * Eliminar un empleado.
 * @param {number|string} empleadoId - ID del empleado a eliminar.
 * @returns {Promise<Object>} - Mensaje de éxito.
 */
export const deleteEmpleado = async (empleadoId) => {
  try {
    console.log(`empleadosService: Eliminando empleado ID ${empleadoId}...`);
    const result = await apiFetch(`empleados/${empleadoId}`, {
      method: 'DELETE',
    });
    console.log('empleadosService: Empleado eliminado con éxito:', result);
    return result;
  } catch (error) {
    console.error(`empleadosService: Error al eliminar empleado ID ${empleadoId}:`, error);
    throw error;
  }
};
