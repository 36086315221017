// src/Servicios/serviciosService.js

import { apiFetch } from './api';

/**
 * Obtener todos los servicios.
 * @returns {Promise<Array>} - Lista de servicios.
 */
export const fetchServicios = async () => {
  try {
    console.log('serviciosService: Obteniendo servicios...');
    const data = await apiFetch('servicios', {
      method: 'GET',
    });
    console.log('serviciosService: Servicios obtenidos:', data);
    return data;
  } catch (error) {
    console.error('serviciosService: Error al obtener servicios:', error);
    throw error;
  }
};

/**
 * Crear un nuevo servicio.
 * @param {Object} servicioData - Datos del nuevo servicio.
 * @returns {Promise<Object>} - Servicio creado.
 */
export const createServicio = async (servicioData) => {
  try {
    console.log('serviciosService: Creando servicio con datos:', servicioData);
    const data = await apiFetch('servicios', {
      method: 'POST',
      body: JSON.stringify(servicioData),
    });
    console.log('serviciosService: Servicio creado:', data);
    return data;
  } catch (error) {
    console.error('serviciosService: Error al crear servicio:', error);
    throw error;
  }
};

/**
 * Actualizar un servicio existente.
 * @param {number|string} id - ID del servicio a actualizar.
 * @param {Object} servicioData - Datos actualizados del servicio.
 * @returns {Promise<Object>} - Servicio actualizado.
 */
export const updateServicio = async (id, servicioData) => {
  try {
    console.log(`serviciosService: Actualizando servicio con ID ${id} y datos:`, servicioData);
    const data = await apiFetch(`servicios/${id}`, {
      method: 'PUT',
      body: JSON.stringify(servicioData),
    });
    console.log(`serviciosService: Servicio con ID ${id} actualizado:`, data);
    return data;
  } catch (error) {
    console.error('serviciosService: Error al actualizar servicio:', error);
    throw error;
  }
};

/**
 * Eliminar un servicio.
 * @param {number|string} id - ID del servicio a eliminar.
 * @returns {Promise<Object>} - Mensaje de éxito.
 */
export const deleteServicio = async (id) => {
  try {
    console.log(`serviciosService: Eliminando servicio con ID ${id}`);
    const data = await apiFetch(`servicios/${id}`, {
      method: 'DELETE',
    });

    if (data === null) {
      console.log('serviciosService: Servicio eliminado exitosamente (No Content).');
    } else {
      console.log('serviciosService: Servicio eliminado:', data);
    }

    return data;
  } catch (error) {
    console.error('serviciosService: Error al eliminar servicio:', error);
    throw error;
  }
};
