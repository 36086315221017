// src/components/CalendarioReservas/CalendarioReservas.jsx

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CalendarioReservas.css';
import { useReservas } from '../../hooks/useReservas';
import { useServicios } from '../../hooks/useServicios';
import { useEmpleados } from '../../hooks/useEmpleados';
import { useTurnos } from '../../hooks/useTurnos';
import { toast } from 'react-toastify';

const CalendarioReservas = ({ puesto, onReservaAdded, onTurnoChanged }) => {
  // ------------------------
  // Estados locales
  // ------------------------
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState('');
  const [clienteNombre, setClienteNombre] = useState('');
  const [clienteContacto, setClienteContacto] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [reservaEnEdicion, setReservaEnEdicion] = useState(null);
  const [reservaExpandida, setReservaExpandida] = useState(null);
  const [selectedEmpleado, setSelectedEmpleado] = useState(null);

  // ------------------------
  // Hooks personalizados
  // ------------------------
  const {
    reservas,
    reservasMes,
    fetchReservasData,
    fetchReservasMesData,
    handleCrearOActualizarReserva,
    handleEliminarReserva,
    handleActualizarEstadoReserva,
    parseTimeToDate,
    getFormattedDate
  } = useReservas(puesto);

  const {
    servicios,
    selectedServicio,
    setSelectedServicio,
    loadServicios,
    loadingServicios
  } = useServicios();

  const {
    empleados,
    fetchEmpleadosData
  } = useEmpleados();

  const {
    turnoAbierto,
    fetchTurnosData,
    handleAbrirTurno,
    handleCerrarTurno,
    handleCambiarEmpleado
  } = useTurnos(puesto);

  // ------------------------
  // useMemo y useCallback
  // ------------------------
  const currentDateTime = useMemo(() => new Date(), []);
  const holidays = useMemo(() => ['2023-12-25', '2024-01-01'], []);

  const isPastDate = useCallback((date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  }, []);

  const toggleReserva = useCallback((id) => {
    setReservaExpandida((prev) => (prev === id ? null : id));
  }, []);

  // Para colorear el calendario
  const tileClassName = useCallback(
    ({ date, view }) => {
      if (view === 'month') {
        const dateString = date.toISOString().split('T')[0];
        const isHoliday = holidays.includes(dateString);
        const isReserved = reservasMes.some(
          (res) => res.fechaReserva === dateString
        );
        const isSelected = dateString === getFormattedDate(selectedDate);
        const isToday = dateString === new Date().toISOString().split('T')[0];

        let className = '';
        if (isHoliday) className += ' react-calendar__tile--holiday';
        if (isReserved) className += ' react-calendar__tile--reserved';
        if (isSelected) className += ' react-calendar__tile--selected';
        if (isToday) className += ' react-calendar__tile--today';

        return className.trim();
      }
      return null;
    },
    [holidays, reservasMes, selectedDate, getFormattedDate]
  );

  // ------------------------
  // Manejo de Fecha y Servicios
  // ------------------------
  const handleDateChange = useCallback(
    (date) => {
      setSelectedDate(date);
      fetchReservasData(date);
    },
    [fetchReservasData]
  );

  const handleServicioDropdownClick = useCallback(() => {
    loadServicios();
  }, [loadServicios]);

  const handleServicioChange = useCallback(
    (e) => {
      const servicioId = parseInt(e.target.value, 10);
      const servicioSeleccionado = servicios.find((serv) => serv.id === servicioId);
      setSelectedServicio(servicioSeleccionado || null);
    },
    [servicios, setSelectedServicio]
  );

  // ------------------------
  // Manejo Hora y Cliente
  // ------------------------
  const handleStartTimeChange = useCallback((e) => {
    setStartTime(e.target.value);
  }, []);

  const handleClienteChange = useCallback((e) => {
    const { name, value } = e.target;
    if (name === 'clienteNombre') {
      setClienteNombre(value);
    } else if (name === 'clienteContacto') {
      setClienteContacto(value);
    }
  }, []);

  // ------------------------
  // Editar / Cancelar Edición
  // ------------------------
  const handleEditarReserva = useCallback(
    (reserva) => {
      setReservaEnEdicion(reserva);
      const srv = servicios.find((s) => s.id === reserva.servicioId) || null;
      setSelectedServicio(srv);
      setStartTime(reserva.horaInicio);
      setClienteNombre(reserva.clienteNombre);
      setClienteContacto(reserva.clienteContacto);
      setSelectedDate(new Date(reserva.fechaReserva));
    },
    [servicios]
  );

  const handleCancelarEdicion = useCallback(() => {
    setReservaEnEdicion(null);
    setStartTime('');
    setSelectedServicio(null);
    setClienteNombre('');
    setClienteContacto('');
    setErrorMessage('');
  }, []);

  // ------------------------
  // Crear o Actualizar Reserva (1 toast por acción)
  // ------------------------
  const handleSubmitReserva = useCallback(
    async (e) => {
      e.preventDefault();

      if (!selectedServicio || !startTime) {
        setErrorMessage('Selecciona un servicio e ingresa una hora de inicio válida.');
        toast.error('Selecciona un servicio e ingresa una hora de inicio válida.');
        return;
      }

      const formattedDate = getFormattedDate(selectedDate);
      if (!formattedDate) {
        setErrorMessage('Fecha seleccionada inválida.');
        toast.error('Fecha seleccionada inválida.');
        return;
      }

      const hoy = new Date();
      const selectedClean = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        0, 0, 0, 0
      );
      const hoyClean = new Date(
        hoy.getFullYear(),
        hoy.getMonth(),
        hoy.getDate(),
        0, 0, 0, 0
      );

      if (selectedClean < hoyClean) {
        setErrorMessage('No puedes reservar en días pasados.');
        toast.error('No puedes reservar en días pasados.');
        return;
      }

      const trabajadorId =
        selectedEmpleado?.id ||
        (turnoAbierto ? turnoAbierto.empleado_id : null);
      if (!trabajadorId) {
        setErrorMessage('No hay un empleado asignado para esta reserva.');
        toast.error('No hay un empleado asignado para esta reserva.');
        return;
      }

      const reservaData = {
        trabajadorId,
        fechaReserva: formattedDate,
        horaInicio: startTime,
        duracion: selectedServicio.duracion,
        clienteNombre: clienteNombre.trim() || 'Cliente Genérico',
        clienteContacto: clienteContacto.trim() || 'Sin Contacto',
        servicioId: selectedServicio.id,
        servicioDetalle: selectedServicio
      };

      try {
        await handleCrearOActualizarReserva(reservaEnEdicion, reservaData);
        onReservaAdded && onReservaAdded(reservaEnEdicion);

        setStartTime('');
        setSelectedServicio(null);
        setClienteNombre('');
        setClienteContacto('');
        setReservaEnEdicion(null);
        setErrorMessage('');

        toast.success(
          reservaEnEdicion
            ? 'Reserva actualizada exitosamente.'
            : 'Reserva creada exitosamente.'
        );
      } catch (error) {
        console.error('Error al guardar la reserva:', error);
        setErrorMessage('No se pudo guardar la reserva. Verifica los datos.');
        toast.error('No se pudo guardar la reserva. Verifica los datos.');
      }
    },
    [
      selectedServicio,
      startTime,
      clienteNombre,
      clienteContacto,
      selectedDate,
      turnoAbierto,
      selectedEmpleado,
      handleCrearOActualizarReserva,
      onReservaAdded,
      getFormattedDate,
      reservaEnEdicion
    ]
  );

  // ------------------------
  // Eliminar Reserva (1 confirm + 1 toast)
  // ------------------------
  const handleDeleteReserva = useCallback(
    async (reservaId) => {
      if (window.confirm('¿Estás seguro de eliminar esta reserva?')) {
        try {
          await handleEliminarReserva(reservaId);
          toast.success('Reserva eliminada exitosamente.');
          await fetchReservasData(selectedDate);
        } catch (error) {
          console.error('Error al eliminar la reserva:', error);
          toast.error('Hubo un error al eliminar la reserva.');
        }
      }
    },
    [handleEliminarReserva, fetchReservasData, selectedDate]
  );

  // ------------------------
  // Cambiar Estado (1 toast)
  // ------------------------
  const handleEstadoReserva = useCallback(
    async (reservaId, estado) => {
      try {
        await handleActualizarEstadoReserva(reservaId, estado);
        toast.success(`Estado de la reserva actualizado a "${estado}".`);
        await fetchReservasData(selectedDate);
      } catch (error) {
        console.error('Error al actualizar el estado de la reserva:', error);
        toast.error('No se pudo actualizar el estado de la reserva.');
      }
    },
    [handleActualizarEstadoReserva, fetchReservasData, selectedDate]
  );

  // ------------------------
  // Efectos
  // ------------------------
  useEffect(() => {
    if (puesto.id) {
      fetchEmpleadosData();
    }
  }, [puesto.id, fetchEmpleadosData]);

  useEffect(() => {
    if (puesto.id) {
      fetchReservasData(selectedDate);
      fetchTurnosData();
    }
  }, [puesto.id, selectedDate, fetchReservasData, fetchTurnosData]);

  useEffect(() => {
    if (puesto.id) {
      fetchReservasMesData(activeStartDate);
    }
  }, [puesto.id, activeStartDate, fetchReservasMesData]);

  const intervalRef = useMemo(() => {
    return setInterval(() => {
      // Lógica periódica si deseas
    }, 60000);
  }, []);

  useEffect(() => {
    return () => clearInterval(intervalRef);
  }, [intervalRef]);

  // ------------------------
  // Turnos (1 toast por acción)
  // ------------------------
  const abrirTurno = useCallback(async () => {
    if (!selectedEmpleado) {
      toast.error('Selecciona un empleado para abrir un turno.');
      return;
    }
    try {
      await handleAbrirTurno(selectedEmpleado.id);
      toast.success('Turno abierto exitosamente.');
      onTurnoChanged && onTurnoChanged(puesto.id);
    } catch (error) {
      console.error('Error al abrir el turno:', error);
      toast.error('No se pudo abrir el turno.');
    }
  }, [selectedEmpleado, handleAbrirTurno, onTurnoChanged, puesto.id]);

  const cerrarTurno = useCallback(async () => {
    if (window.confirm('¿Cerrar este turno?')) {
      try {
        await handleCerrarTurno();
        toast.success('Turno cerrado exitosamente.');
        onTurnoChanged && onTurnoChanged(puesto.id);
      } catch (error) {
        console.error('Error al cerrar el turno:', error);
        toast.error('No se pudo cerrar el turno.');
      }
    }
  }, [handleCerrarTurno, onTurnoChanged, puesto.id]);

  const cambiarEmpleado = useCallback(async () => {
    if (!selectedEmpleado) {
      toast.error('Selecciona un empleado para cambiar.');
      return;
    }
    if (!turnoAbierto) {
      toast.error('No hay un turno abierto para cambiar de empleado.');
      return;
    }
    try {
      await handleCambiarEmpleado(selectedEmpleado.id, turnoAbierto.id);
      toast.success('Empleado cambiado exitosamente.');
      onTurnoChanged && onTurnoChanged(puesto.id);
    } catch (error) {
      console.error('Error al cambiar de empleado:', error);
      toast.error('No se pudo cambiar el empleado.');
    }
  }, [selectedEmpleado, turnoAbierto, handleCambiarEmpleado, onTurnoChanged, puesto.id]);

  // ------------------------
  // Otros
  // ------------------------
  const estados = ['pendiente', 'en_proceso', 'finalizada'];

  // ------------------------
  // Render
  // ------------------------
  return (
    <div className="calendario-reservas-container">

      {/* Calendario */}
      <div className="calendario">
        <h3>Calendario</h3>
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
          onActiveStartDateChange={({ activeStartDate }) =>
            setActiveStartDate(activeStartDate)
          }
          tileClassName={tileClassName}
          tileDisabled={({ date, view }) => view === 'month' && isPastDate(date)}
        />
        <p>Reservas para: {selectedDate.toLocaleDateString()}</p>
      </div>

      {/* Reservas del Día */}
      <div className="reservas">
        <h4>Reservas del día {selectedDate.toLocaleDateString()}:</h4>
        {reservas.length === 0 ? (
          <p>No hay reservas para este día.</p>
        ) : (
          <ul className="lista-reservas">
            {reservas.map((reserva) => {
              const reservaInicio = parseTimeToDate(reserva.fechaReserva, reserva.horaInicio);
              const reservaFin = parseTimeToDate(reserva.fechaReserva, reserva.horaFin);
              const isCurrentReservation =
                currentDateTime >= reservaInicio && currentDateTime < reservaFin;

              return (
                <li
                  key={reserva.id}
                  className={`reserva-item ${
                    reserva.estado === 'finalizada' ? 'completada' : ''
                  } ${isCurrentReservation ? 'reserva-actual' : ''}`}
                >
                  <div className="reserva-summary" onClick={() => toggleReserva(reserva.id)}>
                    <div><strong>Servicio:</strong> {reserva.nombreServicio || 'N/D'}</div>
                    <div><strong>Hora Inicio:</strong> {reserva.horaInicio}</div>
                    <div><strong>Empleado:</strong> {reserva.empleadoNombre}</div>
                    <div><strong>Estado:</strong> {reserva.estado}</div>
                  </div>

                  {reservaExpandida === reserva.id && (
                    <div className="reserva-details">
                      <div><strong>Cliente:</strong> {reserva.clienteNombre}</div>
                      <div><strong>Contacto:</strong> {reserva.clienteContacto}</div>
                      <div><strong>Hora Fin:</strong> {reserva.horaFin}</div>
                      <div>
                        <strong>Total:</strong> $
                        {parseFloat(reserva.costoServicio || 0).toLocaleString()}
                      </div>
                      <div className="reserva-actions">
                        <label>Cambiar estado:</label>
                        <select
                          value={reserva.estado}
                          onChange={(e) => handleEstadoReserva(reserva.id, e.target.value)}
                        >
                          {estados.map((est) => (
                            <option key={est} value={est}>
                              {est}
                            </option>
                          ))}
                        </select>
                        <button className="btn btn-editar" onClick={() => handleEditarReserva(reserva)}>
                          Editar
                        </button>
                        <button className="btn btn-eliminar" onClick={() => handleDeleteReserva(reserva.id)}>
                          Eliminar
                        </button>
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>

      {/* Formulario de Reserva */}
      <div className="formulario">
        <h4>{reservaEnEdicion ? 'Editar Reserva' : 'Crear Reserva'}</h4>
        <form onSubmit={handleSubmitReserva}>
          <div className="form-group">
            <label>Selecciona un servicio:</label>
            <select
              onChange={handleServicioChange}
              value={selectedServicio?.id || ''}
              onClick={handleServicioDropdownClick}
              required
            >
              <option value="">-- Seleccionar Servicio --</option>
              {loadingServicios && <option>Cargando servicios...</option>}
              {!loadingServicios &&
                servicios.map((serv) => (
                  <option key={serv.id} value={serv.id}>
                    {serv.nombre_servicio} - {serv.duracion} min - $
                    {parseFloat(serv.costo).toLocaleString()}
                  </option>
                ))}
              {!loadingServicios && servicios.length === 0 && (
                <option>No hay servicios disponibles</option>
              )}
            </select>
          </div>

          <div className="form-group">
            <label>Fecha de reserva:</label>
            <input
              type="date"
              value={getFormattedDate(selectedDate)}
              onChange={(e) => setSelectedDate(new Date(e.target.value))}
              min={getFormattedDate(new Date())}
              required
            />
          </div>

          <div className="form-group">
            <label>Hora de inicio (HH:MM):</label>
            <input
              type="time"
              value={startTime}
              onChange={handleStartTimeChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Nombre del cliente:</label>
            <input
              type="text"
              value={clienteNombre}
              onChange={handleClienteChange}
              name="clienteNombre"
              placeholder="Nombre del cliente"
              required
            />
          </div>

          <div className="form-group">
            <label>Contacto del cliente:</label>
            <input
              type="text"
              value={clienteContacto}
              onChange={handleClienteChange}
              name="clienteContacto"
              placeholder="Número de contacto (opcional)"
            />
          </div>

          {errorMessage && <p className="error-text">{errorMessage}</p>}

          <button type="submit" className="btn btn-primary">
            {reservaEnEdicion ? 'Actualizar Reserva' : 'Crear Reserva'}
          </button>
          {reservaEnEdicion && (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleCancelarEdicion}
            >
              Cancelar
            </button>
          )}
        </form>
      </div>

      {/* Gestión de Turnos */}
      <div className="gestion-turnos">
        <h4>Gestión de Turnos</h4>
        {turnoAbierto ? (
          <div className="turno-abierto">
            <p>
              Turno abierto con:{' '}
              <strong>
                {empleados.find((e) => e.id === turnoAbierto.empleado_id)?.nombre || 'Desconocido'}
              </strong>
            </p>
            <button onClick={cerrarTurno} className="btn btn-cerrar-turno">
              Cerrar Turno
            </button>
            <div className="cambiar-empleado">
              <label>Cambiar Empleado:</label>
              <select
                onChange={(e) =>
                  setSelectedEmpleado(
                    empleados.find((emp) => emp.id === parseInt(e.target.value, 10)) || null
                  )
                }
                value={selectedEmpleado?.id || ''}
                required
              >
                <option value="">-- Seleccionar Empleado --</option>
                {empleados.map((empleado) => (
                  <option key={empleado.id} value={empleado.id}>
                    {empleado.nombre} - {empleado.email}
                  </option>
                ))}
              </select>
              <button
                onClick={cambiarEmpleado}
                className="btn btn-cambiar-empleado"
                disabled={!selectedEmpleado}
              >
                Cambiar Empleado
              </button>
            </div>
          </div>
        ) : (
          <div className="turno-cerrado">
            <p>No hay un turno abierto actualmente.</p>
            <div className="abrir-turno">
              <label>Selecciona un empleado para abrir un turno:</label>
              <select
                onChange={(e) =>
                  setSelectedEmpleado(
                    empleados.find((emp) => emp.id === parseInt(e.target.value, 10)) || null
                  )
                }
                value={selectedEmpleado?.id || ''}
                required
              >
                <option value="">-- Seleccionar Empleado --</option>
                {empleados.map((empleado) => (
                  <option key={empleado.id} value={empleado.id}>
                    {empleado.nombre} - {empleado.email}
                  </option>
                ))}
              </select>
              <button
                onClick={abrirTurno}
                className="btn btn-abrir-turno"
                disabled={!selectedEmpleado}
              >
                Abrir Turno
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarioReservas;
