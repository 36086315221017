// src/components/Home/Home.jsx

import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet-async';
import './Home.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Home = () => {
  const [showBanner, setShowBanner] = useState(true);
  const [productosDestacados, setProductosDestacados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';

  // Función para obtener productos destacados
  const fetchProductos = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}productos/destacados`, {
        headers: {
          'Content-Type': 'application/json',
          // Incluye el token de autenticación si es necesario
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error('Error al obtener los productos');
      const data = await response.json();
      setProductosDestacados(data);
    } catch (error) {
      setError('No se pudieron cargar los productos.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchProductos();
  }, [fetchProductos]);

  // Personalización de las flechas
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow next-arrow`}
        style={{ ...style }}
        onClick={onClick}
        aria-label="Siguiente"
      >
        &#9654;
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow prev-arrow`}
        style={{ ...style }}
        onClick={onClick}
        aria-label="Anterior"
      >
        &#9664;
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <>
      <Helmet>
        <title>Maryland Beauty & Care | Productos para el Cabello y Servicios de Peluquería</title>
        <meta
          name="description"
          content="Maryland Beauty & Care ofrece los mejores productos para el cuidado del cabello, además de servicios exclusivos de peluquería en Santa Cruz, 6ta Región."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "HairSalon",
              "name": "Maryland Beauty & Care",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Rafael Casanova 222",
                "addressLocality": "Santa Cruz",
                "addressRegion": "VI Región",
                "postalCode": "3230000",
                "addressCountry": "CL"
              },
              "url": "https://marylandbeauty.cl"
            }
          `}
        </script>
      </Helmet>
      <div className="home-container">
        {showBanner && (
          <div className="construction-banner">
            🚧 Este sitio está en construcción. Algunas funcionalidades pueden no estar disponibles. 🚧
            <button
              className="close-banner-btn"
              onClick={() => setShowBanner(false)}
              aria-label="Cerrar aviso de construcción"
            >
              ✖
            </button>
          </div>
        )}
        <div className="text-center mt-4">
          <Link to="/productos" className="btn-ver-todos" aria-label="Ver todos los productos">
            Ver Todos los Productos
          </Link>
        </div>
        {/* Carrusel de Productos Destacados */}
        <section className="featured-products py-3">
          <div className="container">
            <h2 className="text-center mb-5">Productos Destacados</h2>
            {loading ? (
              <div className="text-center">Cargando productos...</div>
            ) : error ? (
              <div className="text-center">Error: {error}</div>
            ) : (
              <div className="carousel-wrapper">
                <Slider {...settings}>
                  {productosDestacados.map((producto) => (
                    <div
                      className="product-card"
                      key={producto.id}
                      onClick={() => navigate(`/productos/${producto.id}`)}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="card-content">
                        <h3 className="product-title">{producto.nombre}</h3>
                        {producto.primera_imagen ? (
                          <img
                            src={producto.primera_imagen}
                            alt={producto.nombre}
                            className="img-fluid mb-3 product-image"
                            loading="lazy"
                            onError={(e) => { e.target.src = '/images/placeholder.jpg'; }}
                          />
                        ) : (
                          <img
                            src="/images/placeholder.jpg"
                            alt="Sin imagen disponible"
                            className="img-fluid mb-3 product-image"
                          />
                        )}
                        <strong className="product-price">${producto.precio}</strong>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>
        </section>

        {/* Próximamente */}
        <section className="coming-soon py-5 bg-light">
          <div className="container text-center">
            <h2 className="mb-4">¡Próximamente!</h2>
            <p>
              Maryland Beauty & Care estará abriendo sus puertas muy pronto. Estamos trabajando arduamente para ofrecerte
              los mejores servicios de peluquería y belleza.
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
