// src/components/ventas/GestionarDestacados.jsx

import React, { useState, useEffect } from 'react';
import './GestionarDestacados.css';

const GestionarDestacados = () => {
  const [productos, setProductos] = useState([]);
  const [destacados, setDestacados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Asegúrate de que API_URL esté correctamente configurado
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';

  // Obtener el token de autenticación de localStorage
  const token = localStorage.getItem('token');

  const obtenerProductos = async () => {
    try {
      const response = await fetch(`${API_URL}productos`);
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error al obtener los productos: ${response.status} ${response.statusText} - ${errorText}`);
      }
      const data = await response.json();
      setProductos(data);
      console.log('Productos obtenidos exitosamente:', data);
    } catch (err) {
      console.error('Error en obtenerProductos:', err);
      setError(err.message || 'No se pudieron cargar los productos.');
    }
  };

  const obtenerDestacados = async () => {
    try {
      if (!token) {
        throw new Error('No se encontró un token de autenticación. Por favor, inicia sesión nuevamente.');
      }

      const response = await fetch(`${API_URL}destacados`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error al obtener los destacados: ${response.status} ${response.statusText} - ${errorText}`);
      }

      const data = await response.json();
      const destacadosIds = data.map(producto => producto.id);
      setDestacados(destacadosIds);
      console.log('Destacados obtenidos exitosamente:', data);
    } catch (err) {
      console.error('Error en obtenerDestacados:', err);
      setError(err.message || 'No se pudieron cargar los destacados.');
    }
  };

  // Inicializar datos
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await obtenerProductos();
      await obtenerDestacados();
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Función para agregar un producto a destacados
  const agregarDestacado = async (productoId) => {
    try {
      if (!token) {
        throw new Error('No se encontró un token de autenticación. Por favor, inicia sesión nuevamente.');
      }

      const response = await fetch(`${API_URL}destacados`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ producto_id: productoId }),
      });

      const data = await response.json();

      if (response.ok) {
        setDestacados([...destacados, productoId]);
        console.log(`Producto ID ${productoId} agregado a destacados.`);
      } else {
        throw new Error(data.error || 'Error al agregar a destacados.');
      }
    } catch (err) {
      console.error('Error en agregarDestacado:', err);
      setError(err.message);
    }
  };

  // Función para eliminar un producto de destacados
  const eliminarDestacado = async (productoId) => {
    try {
      if (!token) {
        throw new Error('No se encontró un token de autenticación. Por favor, inicia sesión nuevamente.');
      }

      const response = await fetch(`${API_URL}destacados/${productoId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        setDestacados(destacados.filter(id => id !== productoId));
        console.log(`Producto ID ${productoId} eliminado de destacados.`);
      } else {
        throw new Error(data.error || 'Error al eliminar de destacados.');
      }
    } catch (err) {
      console.error('Error en eliminarDestacado:', err);
      setError(err.message);
    }
  };

  // Manejar errores y mensajes
  const handleCloseError = () => {
    setError(null);
  };

  return (
    <div className="gestionar-destacados-container">
      <h2>Gestionar Productos Destacados</h2>

      {loading && <p>Cargando...</p>}

      {error && (
        <div className="error-message">
          <span>{error}</span>
          <button onClick={handleCloseError}>&times;</button>
        </div>
      )}

      {!loading && productos.length > 0 && (
        <table className="productos-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Precio</th>
              <th>Stock</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {productos.map(producto => (
              <tr key={producto.id}>
                <td>{producto.id}</td>
                <td>{producto.nombre}</td>
                <td>${producto.precio.toFixed(2)}</td>
                <td>{producto.stock}</td>
                <td>
                  {destacados.includes(producto.id) ? (
                    <button
                      className="btn eliminar-destacado"
                      onClick={() => eliminarDestacado(producto.id)}
                    >
                      <i className="fas fa-star fa-lg"></i> Quitar Destacado
                    </button>
                  ) : (
                    <button
                      className="btn agregar-destacado"
                      onClick={() => agregarDestacado(producto.id)}
                    >
                      <i className="far fa-star fa-lg"></i> Marcar como Destacado
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {!loading && productos.length === 0 && <p>No hay productos disponibles.</p>}
    </div>
  );
};

export default GestionarDestacados;
