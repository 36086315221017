// src/components/AgregarPuesto/AgregarPuesto.js

import React, { useState } from 'react';
import { createPuesto } from '../Servicios/puestosService';

const AgregarPuesto = ({ onPuestoAdded }) => {
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleAgregarPuesto = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    if (!nombre || !descripcion) {
      setErrorMessage('Todos los campos son obligatorios.');
      return;
    }

    const nuevoPuesto = {
      nombre,
      descripcion,
    };

    try {
      const puestoCreado = await createPuesto(nuevoPuesto);
      onPuestoAdded(puestoCreado);

      setNombre('');
      setDescripcion('');
      setErrorMessage('');
      alert('Puesto agregado exitosamente');
    } catch (error) {
      console.error('Error al agregar el puesto:', error);
      setErrorMessage('No se pudo agregar el puesto.');
    }
  };

  return (
    <form className="agregar-puesto-form" onSubmit={handleAgregarPuesto}>
      <label>Nombre del Puesto:</label>
      <input
        type="text"
        value={nombre}
        onChange={(e) => setNombre(e.target.value)}
        required
      />

      <label>Descripción:</label>
      <textarea
        value={descripcion}
        onChange={(e) => setDescripcion(e.target.value)}
        required
      ></textarea>

      {errorMessage && <p className="error-text">{errorMessage}</p>}
      <button type="submit" className="btn btn-agregar">
        Agregar Puesto
      </button>
    </form>
  );
};

export default AgregarPuesto;
