// src/components/ReporteServicios/ReporteServicios.jsx

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { apiFetch } from '../Servicios/api'; // Ajusta la ruta a tu api.js
import './ReporteServicios.css';

// Extrae la parte "YYYY-MM-DD" de un ISO string. Ej: "2024-12-27T00:00:00.000Z" -> "2024-12-27"
const parseIsoDate = (isoString) => {
  if (!isoString) return '';
  const [yyyyMmDd] = isoString.split('T');
  return yyyyMmDd || isoString;
};

// Convierte "HH:MM:SS" en un formato más amigable (por ejemplo, "10:34 a. m.")
const formatTime = (timeString) => {
  if (!timeString) return '';
  const dateObj = new Date(`1970-01-01T${timeString}`);
  if (isNaN(dateObj.getTime())) {
    return timeString;
  }
  return dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

/**
 * Combina fechaReserva (ISO) + horaInicio -> "DD/MM/YYYY HH:mm"
 */
const formatFechaHoraReserva = (isoDate, hourString) => {
  if (!isoDate && !hourString) return '';
  const yyyyMmDd = parseIsoDate(isoDate);
  if (!yyyyMmDd) return `${isoDate} ${hourString}`.trim();

  const combined = `${yyyyMmDd}T${hourString}`;
  const dateObj = new Date(combined);
  if (isNaN(dateObj.getTime())) {
    return `${isoDate} ${hourString}`.trim();
  }
  return dateObj.toLocaleString();
};

// Elimina .00 y deja un decimal simple
const formatDecimal = (value) => {
  if (value === null || value === undefined) return '';
  const num = parseFloat(value);
  if (isNaN(num)) return String(value);
  if (Number.isInteger(num)) return num.toString();
  return num.toString();
};

const ReporteServicios = () => {
  // Catálogos
  const [puestos, setPuestos] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [turnos, setTurnos] = useState([]);

  // Filtros
  const [selectedPuesto, setSelectedPuesto] = useState('');
  const [selectedWorker, setSelectedWorker] = useState('');
  const [selectedTurno, setSelectedTurno] = useState('');
  const [inicio, setInicio] = useState('');
  const [fin, setFin] = useState('');

  // Tipo de reporte
  const [reportType, setReportType] = useState('detallado');
  const [reporte, setReporte] = useState([]);

  // Cargar puestos y empleados
  useEffect(() => {
    const fetchPuestos = async () => {
      try {
        const data = await apiFetch('puestos');
        setPuestos(data);
      } catch (error) {
        console.error('Error al obtener puestos:', error);
        toast.error(error.message || 'No se pudieron cargar los puestos.');
      }
    };

    const fetchEmpleados = async () => {
      try {
        const data = await apiFetch('empleados');
        setEmpleados(data);
      } catch (error) {
        console.error('Error al obtener empleados:', error);
        toast.error(error.message || 'No se pudieron cargar los empleados.');
      }
    };

    fetchPuestos();
    fetchEmpleados();
  }, []);

  // Cargar turnos cuando se seleccione un puesto
  useEffect(() => {
    const fetchTurnos = async () => {
      if (!selectedPuesto) {
        setTurnos([]);
        return;
      }
      try {
        const data = await apiFetch(`puestos/${selectedPuesto}/turnos`);
        setTurnos(data);
      } catch (error) {
        console.error('Error al obtener turnos:', error);
        toast.error(error.message || 'No se pudieron cargar los turnos.');
      }
    };

    fetchTurnos();
  }, [selectedPuesto]);

  // Generar reporte (consulta al backend)
  const handleGenerarReporte = async (e) => {
    e.preventDefault();
    if (!selectedPuesto) {
      toast.error('Debes seleccionar un puesto para generar el reporte.');
      return;
    }
    try {
      const params = new URLSearchParams();
      if (inicio) params.append('inicio', inicio);
      if (fin) params.append('fin', fin);
      if (selectedWorker) params.append('workerId', selectedWorker);
      if (selectedTurno) params.append('turnoId', selectedTurno);

      const endpoint = `puestos/${selectedPuesto}/reporte/${reportType}?${params.toString()}`;
      const data = await apiFetch(endpoint);
      console.log('Respuesta del reporte:', data);
      setReporte(data);

      toast.success('Reporte generado correctamente.');
    } catch (error) {
      console.error('Error al generar reporte:', error);
      toast.error(error.message || 'No se pudo generar el reporte.');
    }
  };

  /**
   * Generar PDF con un título mejorado,
   * sin usar la palabra "empleado".
   */
  const handleGenerarPDF = () => {
    if (!reporte || reporte.length === 0) {
      toast.info('No hay datos para generar PDF.');
      return;
    }

    // Tomamos los objetos de puesto y trabajador (si existen)
    const puestoObj = puestos.find((p) => String(p.id) === String(selectedPuesto));
    const trabajadorObj = empleados.find((e) => String(e.id) === String(selectedWorker));

    // Creamos el doc PDF
    const doc = new jsPDF('p', 'pt');
    doc.setFontSize(14);

    // Título base
    let titulo = 'Reporte de Servicios';

    // Agregamos el nombre del puesto
    if (puestoObj) {
      titulo += ` - Puesto: ${puestoObj.nombre}`;
    }
    // Agregamos el nombre del trabajador, sin la palabra "empleado"
    if (trabajadorObj) {
      titulo += ` - ${trabajadorObj.nombre} ${trabajadorObj.apellido}`;
    }

    doc.text(titulo, 40, 40);

    // Definimos columnas y filas
    let columns = [];
    let rows = [];

    if (reportType === 'detallado') {
      columns = [
        { header: 'ID', dataKey: 'id_ts' },
        { header: 'Trabajador', dataKey: 'empleado' },
        { header: 'Servicio', dataKey: 'servicio' },
        { header: 'Costo', dataKey: 'costo' },
        { header: 'Mano Obra', dataKey: 'manoObra' },
        { header: 'Duración', dataKey: 'duracion' },
        { header: 'Fecha/Hora Reserva', dataKey: 'fechaHoraReserva' },
        { header: 'Inicio Turno', dataKey: 'inicioTurno' },
        { header: 'Fin Turno', dataKey: 'finTurno' },
      ];

      rows = reporte.map((item) => ({
        id_ts: item.id_ts,
        empleado: `${item.empleado_nombre || ''} ${item.empleado_apellido || ''}`,
        servicio: item.nombre_servicio_snapshot || '',
        costo: formatDecimal(item.costo_servicio),
        manoObra: formatDecimal(item.mano_de_obra),
        duracion: item.duracion !== null ? `${item.duracion} min` : '',
        fechaHoraReserva: formatFechaHoraReserva(item.fecha_reserva, item.hora_inicio),
        inicioTurno: item.inicio_turno ? new Date(item.inicio_turno).toLocaleString() : '',
        finTurno: item.fin_turno ? new Date(item.fin_turno).toLocaleString() : '',
      }));
    } else {
      // Reporte agregado
      columns = [
        { header: 'Día', dataKey: 'dia' },
        { header: 'Trabajador', dataKey: 'empleado' },
        { header: 'Total Servicios', dataKey: 'total_servicios' },
        { header: 'Total Costo', dataKey: 'total_costo_servicio' },
        { header: 'Total Mano Obra', dataKey: 'total_mano_obra' },
        { header: 'Comisión', dataKey: 'comision' },
      ];

      rows = reporte.map((item) => ({
        dia: item.dia,
        empleado: `${item.empleado_nombre || ''} ${item.empleado_apellido || ''}`,
        total_servicios: item.total_servicios,
        total_costo_servicio: formatDecimal(item.total_costo_servicio),
        total_mano_obra: formatDecimal(item.total_mano_obra),
        comision: formatDecimal(item.comision),
      }));
    }

    doc.autoTable({
      startY: 70,
      head: [columns.map((col) => col.header)],
      body: rows.map((row) => columns.map((col) => row[col.dataKey])),
      styles: { fontSize: 8 },
      headStyles: { fillColor: [0, 102, 204] },
      theme: 'grid',
    });

    doc.save('reporte_servicios.pdf');
  };

  return (
    <div className="reporte-servicios-container">
      <h2>Reporte de Servicios</h2>

      <form onSubmit={handleGenerarReporte} className="reporte-filtros-form">
        <div className="form-group">
          <label>Puesto:</label>
          <select
            value={selectedPuesto}
            onChange={(e) => setSelectedPuesto(e.target.value)}
            required
          >
            <option value="">-- Seleccionar Puesto --</option>
            {puestos.map((p) => (
              <option key={p.id} value={p.id}>
                {p.nombre}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Trabajador (opcional):</label>
          <select
            value={selectedWorker}
            onChange={(e) => setSelectedWorker(e.target.value)}
          >
            <option value="">-- Todos --</option>
            {empleados.map((emp) => (
              <option key={emp.id} value={emp.id}>
                {emp.nombre} {emp.apellido}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Turno (opcional):</label>
          <select
            value={selectedTurno}
            onChange={(e) => setSelectedTurno(e.target.value)}
          >
            <option value="">-- Todos --</option>
            {turnos.map((t) => (
              <option key={t.id} value={t.id}>
                Turno #{t.id} - {t.estado} - {t.inicio_turno}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Fecha Inicio:</label>
          <input
            type="date"
            value={inicio}
            onChange={(e) => setInicio(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Fecha Fin:</label>
          <input
            type="date"
            value={fin}
            onChange={(e) => setFin(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Tipo de Reporte:</label>
          <select
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
          >
            <option value="detallado">Detallado</option>
            <option value="agregado">Agregado</option>
          </select>
        </div>

        <button type="submit" className="btn-generar">
          Generar Reporte
        </button>
      </form>

      <div className="resultado-reporte">
        {Array.isArray(reporte) && reporte.length > 0 ? (
          <>
            <table className="reporte-tabla">
              <thead>
                {reportType === 'detallado' ? (
                  <tr>
                    <th>ID</th>
                    <th>Trabajador</th>
                    <th>Servicio</th>
                    <th>Costo</th>
                    <th>Mano de Obra</th>
                    <th>Duración</th>
                    <th>Fecha/Hora Reserva</th>
                    <th>Inicio Turno</th>
                    <th>Fin Turno</th>
                  </tr>
                ) : (
                  <tr>
                    <th>Día</th>
                    <th>Trabajador</th>
                    <th>Total Servicios</th>
                    <th>Total Costo</th>
                    <th>Total Mano Obra</th>
                    <th>Comisión</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {reportType === 'detallado'
                  ? reporte.map((item, i) => (
                      <tr key={item.id_ts || i}>
                        <td>{item.id_ts}</td>
                        <td>
                          {item.empleado_nombre} {item.empleado_apellido}
                        </td>
                        <td>{item.nombre_servicio_snapshot}</td>
                        <td>{formatDecimal(item.costo_servicio)}</td>
                        <td>{formatDecimal(item.mano_de_obra)}</td>
                        <td>
                          {item.duracion !== null ? `${item.duracion} min` : ''}
                        </td>
                        <td>
                          {formatFechaHoraReserva(item.fecha_reserva, item.hora_inicio)}
                        </td>
                        <td>
                          {item.inicio_turno
                            ? new Date(item.inicio_turno).toLocaleString()
                            : ''}
                        </td>
                        <td>
                          {item.fin_turno
                            ? new Date(item.fin_turno).toLocaleString()
                            : ''}
                        </td>
                      </tr>
                    ))
                  : reporte.map((item, i) => (
                      <tr key={i}>
                        <td>{item.dia}</td>
                        <td>
                          {item.empleado_nombre} {item.empleado_apellido}
                        </td>
                        <td>{item.total_servicios}</td>
                        <td>{formatDecimal(item.total_costo_servicio)}</td>
                        <td>{formatDecimal(item.total_mano_obra)}</td>
                        <td>{formatDecimal(item.comision)}</td>
                      </tr>
                    ))}
              </tbody>
            </table>

            <button onClick={handleGenerarPDF} className="btn-pdf">
              Generar PDF
            </button>
          </>
        ) : (
          <p>No se encontraron datos para el reporte.</p>
        )}
      </div>
    </div>
  );
};

export default ReporteServicios;
