// /src/components/ventas/ListaProductos.jsx

import React, { useState } from 'react';
import DropdownWithCheckbox from './DropdownWithCheckbox';
import { toast } from 'react-toastify';
import './ListaProductos.css'; // Asegúrate de que este archivo CSS está correctamente configurado
import { v4 as uuidv4 } from 'uuid'; // Asegúrate de instalar uuid: npm install uuid

const ListaProductos = ({ productos, categorias, marcas, API_URL, token, fetchProductos }) => {
  // Estados para manejar la expansión de filas, edición de productos y subida de archivos
  const [expandedRows, setExpandedRows] = useState([]);
  const [productoEditando, setProductoEditando] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadMessage, setUploadMessage] = useState('');

  // Función para alternar la expansión de filas
  const toggleRowExpansion = (id) => {
    setExpandedRows((prevState) =>
      prevState.includes(id)
        ? prevState.filter((rowId) => rowId !== id)
        : [...prevState, id]
    );
  };

  // Función para identificar si un ID es un UUID
  const isUUID = (id) => typeof id === 'string' && /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(id);

  // Función para iniciar la edición de un producto
  const handleEditarProducto = (producto) => {
    const mediosConId = producto.medios
      ? producto.medios.map((media) => ({
          ...media,
          id: media.id ? Number(media.id) : uuidv4(), // Convertir a número si existe, o asignar UUID
        }))
      : [];

    setProductoEditando({
      ...producto,
      categoria_ids: producto.categoria_ids || [],
      medios: mediosConId, // Copiar medios existentes con ID
      media_links: producto.media_links || { imagenes: [], videos: [] },
      eliminar_media_ids: [], // Inicializar array para medios a eliminar
    });
  };

  // Función para manejar la eliminación de un producto
  const handleEliminarProducto = async (id) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar este producto?')) return;

    try {
      const response = await fetch(`${API_URL}productos/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        fetchProductos();
        toast.success('Producto eliminado exitosamente.');
      } else {
        toast.error(data.error || 'Error al eliminar el producto.');
      }
    } catch (error) {
      toast.error('Error al eliminar el producto.');
      console.error('Error al eliminar el producto:', error);
    }
  };

  // Función para guardar la edición de un producto
  const handleGuardarEdicion = async () => {
    const { nombre, id, eliminar_media_ids } = productoEditando;

    console.log('Guardando edición para producto ID:', id);
    console.log('eliminar_media_ids antes de filtrar:', eliminar_media_ids);

    if (!nombre.trim()) {
      toast.error('El nombre del producto es obligatorio.');
      return;
    }

    // Verificar si el nombre del producto ha cambiado y si ya existe
    const productoOriginal = productos.find((p) => p.id === id);
    if (
      productoOriginal &&
      nombre.toLowerCase() !== productoOriginal.nombre.toLowerCase()
    ) {
      try {
        const response = await fetch(
          `${API_URL}productos?nombre=${encodeURIComponent(nombre)}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.length > 0) {
            toast.error(`El producto "${nombre}" ya existe.`);
            return;
          }
        }
      } catch (error) {
        console.error('Error al verificar la existencia del producto:', error);
      }
    }

    const formData = new FormData();
    Object.keys(productoEditando).forEach((key) => {
      if (
        key !== 'id' &&
        key !== 'eliminar_media_ids' &&
        productoEditando[key] !== '' &&
        productoEditando[key] !== null &&
        productoEditando[key] !== undefined
      ) {
        if (key === 'categoria_ids') {
          productoEditando.categoria_ids.forEach((cat_id) =>
            formData.append('categoria_ids', Number(cat_id))
          );
        } else if (key === 'medios') {
          productoEditando.medios.forEach((media) => {
            if (typeof media === 'object' && media instanceof File) {
              formData.append('media', media);
            }
          });
        } else if (key === 'media_links') {
          formData.append(
            'media_links',
            JSON.stringify(productoEditando.media_links)
          );
        } else {
          formData.append(key, productoEditando[key]);
        }
      }
    });

    // Filtrar eliminar_media_ids para incluir solo IDs numéricos
    const eliminarMediaIdsFiltrados = eliminar_media_ids
      .filter((mediaId) => !isUUID(mediaId)) // Excluir UUIDs
      .map((mediaId) => Number(mediaId))    // Convertir a número
      .filter((mediaId) => !isNaN(mediaId)); // Excluir conversiones fallidas

    console.log('eliminar_media_ids filtrados para enviar:', eliminarMediaIdsFiltrados);

    // Enviar eliminar_media_ids como una cadena JSON si hay IDs válidos
    if (eliminarMediaIdsFiltrados.length > 0) {
      formData.append('eliminar_media_ids', JSON.stringify(eliminarMediaIdsFiltrados));
    }

    // Añadir log para verificar qué se está enviando en FormData
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    try {
      // Llamar a la función de subida con progreso
      const responseData = await handleUploadMedia(formData); // Capturar la respuesta

      // Manejar la respuesta del backend
      setProductoEditando(null);
      fetchProductos();
      toast.success('Producto actualizado exitosamente.');
    } catch (error) {
      if (error.errors) {
        const mensajes = error.errors.map((err) => err.msg).join('. ');
        toast.error(mensajes);
      } else {
        toast.error(error.error || 'Error al actualizar el producto.');
      }
      console.error('Error al actualizar el producto:', error);
    }
  };

  // Función para manejar cambios en los campos de entrada
  const handleInputChange = (e, isEditing = false) => {
    const { name, value } = e.target;
    if (isEditing) {
      setProductoEditando({ ...productoEditando, [name]: value });
    }
    // Eliminamos el bloque 'else' que intentaba actualizar 'nuevoProducto'
  };

  // Función para manejar cambios en los archivos medios
  const handleMediaChange = (e, isEditing = false) => {
    const files = Array.from(e.target.files);
    if (isEditing) {
      setProductoEditando({
        ...productoEditando,
        medios: [...productoEditando.medios, ...files],
      });
    }
    // Eliminamos el bloque 'else' que intentaba actualizar 'nuevoProducto'
  };

  // Función para manejar la subida con progreso usando XMLHttpRequest
  const handleUploadMedia = (formData) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PATCH', `${API_URL}productos/${productoEditando.id}`, true); // Ajusta la URL según tu configuración

      // Si usas autenticación, incluye el token en las cabeceras
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);

      // Evento de progreso de la subida
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          setUploadPercentage(percentCompleted);
        }
      };

      // Evento de carga completa
      xhr.onload = () => {
        if (xhr.status === 200 || xhr.status === 201) {
          setUploadMessage('Subida exitosa.');
          setUploadPercentage(0);
          try {
            const responseData = JSON.parse(xhr.response);
            resolve(responseData);
          } catch (parseError) {
            resolve(xhr.response);
          }
        } else {
          setUploadMessage('Error en la subida.');
          setUploadPercentage(0);
          try {
            const errorResponse = JSON.parse(xhr.response);
            reject(errorResponse);
          } catch {
            reject('Error desconocido en la subida.');
          }
        }
      };

      // Evento de error en la subida
      xhr.onerror = () => {
        setUploadMessage('Error en la conexión.');
        setUploadPercentage(0);
        reject('Error en la conexión.');
      };

      xhr.send(formData);
    });
  };

  // Función para eliminar un medio existente (archivo subido) o nuevo medio (UUID)
  const handleEliminarMedia = (mediaId) => {
    console.log(`Intentando eliminar medio con ID: ${mediaId}`);
    if (isUUID(mediaId)) {
      // Es un nuevo medio (UUID), simplemente eliminarlo del array medios
      setProductoEditando({
        ...productoEditando,
        medios: productoEditando.medios.filter((media) => media.id !== mediaId),
      });
      console.log(`Medio con UUID ${mediaId} eliminado de medios.`);
    } else {
      // Es un medio existente, agregar su ID a eliminar_media_ids
      const numericId = Number(mediaId);
      console.log(`Medio existente con ID numérico: ${numericId}`);
      if (!isNaN(numericId) && !productoEditando.eliminar_media_ids.includes(numericId)) {
        setProductoEditando({
          ...productoEditando,
          eliminar_media_ids: [...productoEditando.eliminar_media_ids, numericId],
        });
        console.log(`ID ${numericId} agregado a eliminar_media_ids.`);
      } else {
        console.log(`ID ${numericId} ya está en eliminar_media_ids o no es válido.`);
      }
    }
  };

  // Función para manejar cambios en los enlaces de medios externos
  const handleMediaLinkChange = (tipo, index, value) => {
    const updatedMediaLinks = { ...productoEditando.media_links };
    updatedMediaLinks[tipo][index] = value;
    setProductoEditando({ ...productoEditando, media_links: updatedMediaLinks });
  };

  // Función para agregar un nuevo enlace de medio externo
  const handleAddMediaLink = (tipo) => {
    const updatedMediaLinks = { ...productoEditando.media_links };
    updatedMediaLinks[tipo].push('');
    setProductoEditando({ ...productoEditando, media_links: updatedMediaLinks });
  };

  // Función para eliminar un enlace de medio externo
  const handleRemoveMediaLink = (tipo, index) => {
    const updatedMediaLinks = { ...productoEditando.media_links };
    updatedMediaLinks[tipo].splice(index, 1);
    setProductoEditando({ ...productoEditando, media_links: updatedMediaLinks });
  };

  // Función para cancelar la edición de un producto
  const handleCancelarEdicion = () => {
    setProductoEditando(null);
    setUploadPercentage(0);
    setUploadMessage('');
  };

  return (
    <div>
      <h3>Lista de Productos</h3>
      <table className="productos-admin-tabla-productos">
        <thead>
          <tr>
            <th>Media</th>
            <th>Nombre</th>
            <th>Precio</th>
            <th>Stock</th>
            <th>Descripción</th>
            <th>Instrucciones</th>
            <th>Categorías</th>
            <th>Marca</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {productos.map((producto) => {
            const isExpanded = expandedRows.includes(producto.id);
            const categoriasProducto = categorias.filter((cat) =>
              producto.categoria_ids && Array.isArray(producto.categoria_ids)
                ? producto.categoria_ids.includes(cat.id)
                : false
            );
            return (
              <tr key={producto.id}>
                {/* Media */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <>
                      {/* Mostrar medios existentes con opción para eliminar */}
                      {productoEditando.medios && productoEditando.medios.length > 0 && productoEditando.medios.map((mediaItem) => (
                        <div
                          key={mediaItem.id} // Asegúrate de que mediaItem.id es único y definido
                          className={`media-item ${
                            productoEditando.eliminar_media_ids.includes(mediaItem.id)
                              ? 'media-eliminada'
                              : ''
                          }`}
                        >
                          {mediaItem.tipo === 'imagen' ? (
                            <img
                              src={`${API_URL}productos/imagenes/${mediaItem.url}`}
                              alt={producto.nombre}
                              className="imagen-tabla"
                              loading="lazy"
                            />
                          ) : (
                            <video
                              src={`${API_URL}productos/videos/${mediaItem.url}`}
                              controls
                              className="imagen-tabla"
                            />
                          )}
                          {!productoEditando.eliminar_media_ids.includes(mediaItem.id) && (
                            <button
                              className="media-delete-btn btn-eliminar"
                              onClick={() => handleEliminarMedia(mediaItem.id)}
                              title="Eliminar medio"
                            >
                              &times;
                            </button>
                          )}
                          {productoEditando.eliminar_media_ids.includes(mediaItem.id) && (
                            <div className="media-overlay">Eliminado</div>
                          )}
                        </div>
                      ))}

                      {/* Input para nuevos archivos medios */}
                      <input
                        type="file"
                        multiple
                        onChange={(e) => handleMediaChange(e, true)}
                        accept="image/*,video/*"
                        className="media-upload-input"
                      />

                      {/* Media links: Imágenes Externas */}
                      <div className="media-links-section">
                        <h4>Imágenes Externas:</h4>
                        {productoEditando.media_links &&
                          productoEditando.media_links.imagenes &&
                          productoEditando.media_links.imagenes.map((link, index) => (
                            <div key={`external-imagen-${link}-${index}`} className="media-link-item">
                              <input
                                type="url"
                                value={link}
                                onChange={(e) =>
                                  handleMediaLinkChange('imagenes', index, e.target.value)
                                }
                                placeholder="URL de la imagen"
                                className="media-link-input"
                              />
                              <button
                                className="btn-eliminar"
                                onClick={() => handleRemoveMediaLink('imagenes', index)}
                                title="Eliminar enlace de imagen"
                              >
                                Eliminar
                              </button>
                            </div>
                          ))}
                        <button
                          className="btn-agregar-media-link"
                          onClick={() => handleAddMediaLink('imagenes')}
                        >
                          Agregar Imagen
                        </button>
                      </div>

                      {/* Media links: Videos Externos */}
                      <div className="media-links-section">
                        <h4>Videos Externos:</h4>
                        {productoEditando.media_links &&
                          productoEditando.media_links.videos &&
                          productoEditando.media_links.videos.map((link, index) => (
                            <div key={`external-video-${link}-${index}`} className="media-link-item">
                              <input
                                type="url"
                                value={link}
                                onChange={(e) =>
                                  handleMediaLinkChange('videos', index, e.target.value)
                                }
                                placeholder="URL del video (YouTube, Vimeo, etc.)"
                                className="media-link-input"
                              />
                              <button
                                className="btn-eliminar"
                                onClick={() => handleRemoveMediaLink('videos', index)}
                                title="Eliminar enlace de video"
                              >
                                Eliminar
                              </button>
                            </div>
                          ))}
                        <button
                          className="btn-agregar-media-link"
                          onClick={() => handleAddMediaLink('videos')}
                        >
                          Agregar Video
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Mostrar medios existentes */}
                      {producto.medios && producto.medios.length > 0 && producto.medios.map((mediaItem, index) => (
                        mediaItem.tipo === 'imagen' ? (
                          <img
                            key={`imagen-${mediaItem.id}-${index}`} // Asegura que key es única
                            src={`${API_URL}productos/imagenes/${mediaItem.url}`}
                            alt={producto.nombre}
                            className="imagen-tabla"
                            loading="lazy"
                          />
                        ) : (
                          <video
                            key={`video-${mediaItem.id}-${index}`} // Asegura que key es única
                            src={`${API_URL}productos/videos/${mediaItem.url}`}
                            controls
                            className="imagen-tabla"
                          />
                        )
                      ))}
                      {producto.media_links && producto.media_links.imagenes && producto.media_links.imagenes.length > 0 && producto.media_links.imagenes.map((link, index) => (
                        <img
                          key={`external-imagen-${link}-${index}`} // Usa una combinación única
                          src={link}
                          alt={`${producto.nombre} - Imagen Externa ${index + 1}`}
                          className="imagen-tabla"
                          loading="lazy"
                        />
                      ))}
                      {producto.media_links && producto.media_links.videos && producto.media_links.videos.length > 0 && producto.media_links.videos.map((link, index) => (
                        <video
                          key={`external-video-${link}-${index}`} // Usa una combinación única
                          src={link}
                          controls
                          className="imagen-tabla"
                        />
                      ))}
                    </>
                  )}
                </td>

                {/* Nombre */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <input
                      type="text"
                      name="nombre"
                      value={productoEditando.nombre}
                      onChange={(e) => handleInputChange(e, true)}
                      required
                      className="input-nombre"
                    />
                  ) : (
                    producto.nombre
                  )}
                </td>

                {/* Precio */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <input
                      type="number"
                      name="precio"
                      value={productoEditando.precio}
                      onChange={(e) => handleInputChange(e, true)}
                      min="0"
                      step="0.01"
                      required
                      className="input-precio"
                    />
                  ) : (
                    `$${Number(producto.precio).toFixed(2)}`
                  )}
                </td>

                {/* Stock */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <input
                      type="number"
                      name="stock"
                      value={productoEditando.stock}
                      onChange={(e) => handleInputChange(e, true)}
                      min="0"
                      required
                      className="input-stock"
                    />
                  ) : (
                    producto.stock
                  )}
                </td>

                {/* Descripción */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <textarea
                      name="descripcion"
                      value={productoEditando.descripcion}
                      onChange={(e) => handleInputChange(e, true)}
                      placeholder="Descripción corta"
                      rows="3"
                      className="textarea-descripcion"
                    ></textarea>
                  ) : (
                    <div
                      className={`descripcion-cell ${isExpanded ? 'expanded' : 'truncated'}`}
                      onClick={() => toggleRowExpansion(producto.id)}
                    >
                      {producto.descripcion}
                    </div>
                  )}
                </td>

                {/* Instrucciones */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <textarea
                      name="descripcion_larga"
                      value={productoEditando.descripcion_larga}
                      onChange={(e) => handleInputChange(e, true)}
                      placeholder="Descripción detallada"
                      rows="5"
                      className="textarea-descripcion-larga"
                    ></textarea>
                  ) : (
                    <div
                      className={`descripcion-larga-cell ${isExpanded ? 'expanded' : 'truncated'}`}
                      onClick={() => toggleRowExpansion(producto.id)}
                    >
                      {producto.descripcion_larga}
                    </div>
                  )}
                </td>

                {/* Categorías */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <DropdownWithCheckbox
                      label=""
                      options={categorias.map((cat) => ({
                        id: cat.id,
                        nombre: cat.nombre,
                      }))}
                      selectedOptions={productoEditando.categoria_ids.map((id) => Number(id))}
                      setSelectedOptions={(selected) => {
                        const categoriasSeleccionadas = selected.map((id) => Number(id));
                        setProductoEditando({ ...productoEditando, categoria_ids: categoriasSeleccionadas });
                      }}
                      isMultiSelect={true}
                      readOnly={false}
                      className="dropdown-categorias"
                    />
                  ) : (
                    categoriasProducto.map((cat) => cat.nombre).join(', ') || 'Sin categorías'
                  )}
                </td>

                {/* Marca */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <select
                      name="marca_id"
                      value={productoEditando.marca_id || ''}
                      onChange={(e) => handleInputChange(e, true)}
                      required
                      className="select-marca"
                    >
                      <option value="">Seleccionar Marca</option>
                      {marcas.map((marca) => (
                        <option key={marca.id} value={marca.id}>
                          {marca.nombre}
                        </option>
                      ))}
                    </select>
                  ) : (
                    marcas.find((marca) => marca.id === producto.marca_id)?.nombre ||
                    'Sin marca'
                  )}
                </td>

                {/* Acciones */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <>
                      <button className="btn-guardar" onClick={handleGuardarEdicion} disabled={uploadPercentage > 0}>
                        Guardar
                      </button>
                      <button className="btn-cancelar" onClick={handleCancelarEdicion} disabled={uploadPercentage > 0}>
                        Cancelar
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="btn-editar" onClick={() => handleEditarProducto(producto)}>
                        Editar
                      </button>
                      <button className="btn-eliminar" onClick={() => handleEliminarProducto(producto.id)}>
                        Eliminar
                      </button>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Barra de Carga */}
      {uploadPercentage > 0 && (
        <div className="upload-progress-container">
          <div className="upload-progress-bar" style={{ width: `${uploadPercentage}%` }}>
            {uploadPercentage}%
          </div>
        </div>
      )}

      {/* Mensajes de Estado */}
      {uploadMessage && <p className="upload-message">{uploadMessage}</p>}
    </div>
  );
};

export default ListaProductos;
