// src/Servicios/puestosService.js

import { apiFetch } from './api';

/**
 * Obtener todos los puestos.
 * @returns {Promise<Array>} - Lista de puestos.
 */
export const fetchPuestos = async () => {
  try {
  
    const puestos = await apiFetch('puestos', {
      method: 'GET',
    });
  
    return puestos;
  } catch (error) {
    console.error('puestosService: Error al obtener los puestos:', error);
    throw error;
  }
};

/**
 * Crear un nuevo puesto.
 * @param {Object} puestoData - Datos del nuevo puesto.
 * @returns {Promise<Object>} - Puesto creado.
 */
export const createPuesto = async (puestoData) => {
  try {
  
    const newPuesto = await apiFetch('puestos', {
      method: 'POST',
      body: JSON.stringify(puestoData),
    });
 
    return newPuesto;
  } catch (error) {
    console.error('puestosService: Error al crear el puesto:', error);
    throw error;
  }
};

/**
 * Actualizar un puesto existente.
 * @param {number|string} puestoId - ID del puesto a actualizar.
 * @param {Object} puestoData - Datos actualizados del puesto.
 * @returns {Promise<Object>} - Puesto actualizado.
 */
export const updatePuesto = async (puestoId, puestoData) => {
  try {
   
    const updatedPuesto = await apiFetch(`puestos/${puestoId}`, {
      method: 'PUT',
      body: JSON.stringify(puestoData),
    });
  
    return updatedPuesto;
  } catch (error) {
    console.error(`puestosService: Error al actualizar el puesto ID ${puestoId}:`, error);
    throw error;
  }
};

/**
 * Eliminar un puesto.
 * @param {number|string} puestoId - ID del puesto a eliminar.
 * @returns {Promise<Object>} - Mensaje de éxito.
 */
export const deletePuesto = async (puestoId) => {
  try {
   
    const result = await apiFetch(`puestos/${puestoId}`, {
      method: 'DELETE',
    });
   
    return result;
  } catch (error) {
    console.error(`puestosService: Error al eliminar el puesto ID ${puestoId}:`, error);
    throw error;
  }
};

/**
 * Obtener estadísticas de un puesto.
 * @param {number|string} puestoId - ID del puesto para obtener estadísticas.
 * @returns {Promise<Object>} - Estadísticas del puesto.
 */
export const fetchEstadisticas = async (puestoId) => {
  try {
    
    const estadisticas = await apiFetch(`puestos/${puestoId}/estadisticas`, {
      method: 'GET',
    });
 
    return estadisticas;
  } catch (error) {
    console.error(`puestosService: Error al obtener las estadísticas del puesto ID ${puestoId}:`, error);
    throw error;
  }
};
