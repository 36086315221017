import { useState, useCallback } from 'react';
import { fetchEmpleados } from '../components/Servicios/empleadosService';

export function useEmpleados() {
  const [empleados, setEmpleados] = useState([]);

  const fetchEmpleadosData = useCallback(async () => {
    try {
      const data = await fetchEmpleados();
      setEmpleados(data);
    } catch (error) {
      console.error('Error al obtener los empleados:', error);
    }
  }, []);

  return {
    empleados,
    fetchEmpleadosData
  };
}
