// src/pages/ArrendadorDashboard/ArrendadorDashboard.js

import React from 'react';
import SidebarDerecho from '../../Sidebars/SidebarDerecho';
import DashboardContent from '../../DashboardContent/DashboardContent';
import ReporteServicios from '../../ReporteServicios/ReporteServicios';
// Ajusta la ruta de import según la ubicación de tu componente

import './ArrendadorDashboard.css';

const ArrendadorDashboard = () => {
  // Ejemplo: si tu lógica requiere un puesto
  // y ya lo tienes definido en algún lugar, podrías pasarlo así:
  const puesto = { id: 1, nombre: 'Puesto Principal' };

  return (
    <div className="dashboard-container">
      <div className="sidebar-izquierdo">
        {/* Puedes poner aquí tu sidebar izquierdo, si lo tienes */}
      </div>

      <div className="dashboard-content">
        <header className="dashboard-header">
          <h2>Panel Principal</h2>
        </header>

        {/* Este componente maneja la lógica de puestos, calendarios, etc. */}
        <DashboardContent />

        {/* Aquí integras tu Reporte de Servicios */}
        <div className="reporte-servicios-container">
          <h3>Reporte de Servicios</h3>
          {/* Pasamos el puesto como prop, en caso de que sea necesario */}
          <ReporteServicios puesto={puesto} />
        </div>
      </div>

      <SidebarDerecho />
    </div>
  );
};

export default ArrendadorDashboard;
