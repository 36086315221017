import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import '../GoogleLoginButton/GoogleLoginButton.css';
import { useAuth } from '../../contexts/AuthContext';
import { jwtDecode } from 'jwt-decode';

// Custom Hook for handling token refresh
const useRefreshToken = () => {
  const { login } = useAuth();

  const refreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken'); 
      if (!refreshToken) throw new Error('No refresh token available');

      const response = await fetch(`${process.env.REACT_APP_API_URL}auth/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          refreshToken: refreshToken
        }),
      });

      const data = await response.json();

      if (data.token) {
        login({ token: data.token, user: data.user });
        localStorage.setItem('token', data.token);
        localStorage.setItem('refreshToken', data.refreshToken); // Assuming backend also returns a new refresh token
        return true;
      } else {
        throw new Error('Failed to refresh token');
      }
    } catch (error) {
      console.error('Token refresh failed:', error);
      return false;
    }
  };

  return { refreshToken };
};

const GoogleLoginButton = ({ onLoginSuccess }) => {
  const { login, logout } = useAuth();
  const backendUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';
  const { refreshToken } = useRefreshToken(); // Use the custom Hook here

  const handleLogin = async (response) => {
    const { credential } = response;

    try {
      // Send the Google token to the backend for verification
      const res = await fetch(`${backendUrl}auth/google`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: credential }),
      });

      const data = await res.json();

      if (res.ok && data.token) {
        const decodedToken = jwtDecode(data.token);
        const expirationTime = decodedToken.exp;
        const currentTime = Date.now() / 1000;
        const timeout = (expirationTime - currentTime) * 1000;

        if (!data.user) {
          alert('No se recibieron los datos del usuario. Contacta al administrador.');
          return;
        }

        // Store both access and refresh tokens
        login({ token: data.token, user: data.user });
        localStorage.setItem('refreshToken', data.refreshToken); // Assuming backend returns refresh token
        onLoginSuccess();

        if (timeout > 0) {
          setTimeout(async () => {
            if (await refreshToken()) {
              // Token was refreshed, update the timer
              const decodedToken = jwtDecode(localStorage.getItem('token'));
              const newExpirationTime = decodedToken.exp;
              const newCurrentTime = Date.now() / 1000;
              const newTimeout = (newExpirationTime - newCurrentTime) * 1000;
              setTimeout(() => logout(), newTimeout);
            } else {
              logout();
              alert('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.');
            }
          }, timeout - 60000); // Refresh 1 minute before expiration
        }
      } else {
        alert(data.error || 'Error al iniciar sesión con Google.');
      }
    } catch (error) {
      console.error('Error en la autenticación:', error);
      alert('Error en la autenticación.');
    }
  };

  return (
    <div className="google-login-container">
      <GoogleLogin
        onSuccess={handleLogin}
        onError={() => alert('Error al iniciar sesión con Google.')}
      />
    </div>
  );
};

export default GoogleLoginButton;