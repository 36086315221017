// src/components/Servicios/api.js

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/'; // Definir en .env

// Función para obtener los headers con autorización
const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  };
};

// Función centralizada para manejar fetch
export const apiFetch = async (endpoint, options = {}) => {
  const headers = getAuthHeaders();

  const config = {
    ...options,
    headers: {
      ...headers,
      ...(options.headers || {}),
    },
  };

  try {
    const response = await fetch(`${API_URL}${endpoint}`, config);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error ${response.status}: ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    console.error(`Error en la solicitud a ${endpoint}:`, error);
    throw error;
  }
};
