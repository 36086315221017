  // src/components/Pages/Productos/ProductosClientes.jsx

  import React, { useEffect, useState } from 'react';
  import ModalProducto from '../../ModalProducto/ModalProducto.jsx';
  import './ProductosClientes.css';
  import { useLocation, useNavigate, useParams } from 'react-router-dom';

  const ProductosClientes = () => {
    const [productos, setProductos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [modalProducto, setModalProducto] = useState(null);
    const [expandido, setExpandido] = useState({});
    const [filtrosExpandido, setFiltrosExpandido] = useState({
      categorias: false,
      marcas: false,
    });

    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams(); // Obtener el parámetro :id de la URL

    const getQueryParams = () => {
      return new URLSearchParams(location.search);
    };

    // Cargar Categorías
    useEffect(() => {
      const fetchCategorias = async () => {
        try {
          const response = await fetch(`${API_URL}categorias/conteo`);
          if (!response.ok) throw new Error('Error al obtener las categorías');
          const data = await response.json();

          // Asegurar que los IDs son números
          const categoriasNumericas = data
            .filter((categoria) => categoria.conteo > 0)
            .map((categoria) => ({
              ...categoria,
              id: Number(categoria.id),
            }));

          setCategorias(categoriasNumericas);
        } catch (error) {
          console.error('Error al cargar categorías:', error);
          setCategorias([]);
        }
      };

      fetchCategorias();
    }, [API_URL]);

    // Cargar Marcas
    useEffect(() => {
      const fetchMarcas = async () => {
        try {
          const response = await fetch(`${API_URL}marcas/conteo`);
          if (!response.ok) throw new Error('Error al obtener las marcas');
          const data = await response.json();

          // Asegurar que los IDs son números
          const marcasNumericas = data
            .filter((marca) => marca.conteo > 0)
            .map((marca) => ({
              ...marca,
              id: Number(marca.id),
            }));

          setMarcas(marcasNumericas);
        } catch (error) {
          console.error('Error al cargar marcas:', error);
          setMarcas([]);
        }
      };

      fetchMarcas();
    }, [API_URL]);

    // Leer parámetros de la URL (categoría o marca) y aplicarlos a los filtros y a los checkboxes
    useEffect(() => {
      const queryParams = getQueryParams();
      const categoriaParam = queryParams.getAll('categoria');
      const marcaParam = queryParams.getAll('marca');

      const nuevasCategorias = categoriaParam.map(Number).filter((id) => !isNaN(id));
      const nuevasMarcas = marcaParam.map(Number).filter((id) => !isNaN(id));

      // Actualizar la expansión de los filtros
      setFiltrosExpandido({
        categorias: nuevasCategorias.length > 0,
        marcas: nuevasMarcas.length > 0,
      });

      // Fetch productos
      const fetchProductos = async () => {
        setLoading(true);
        setError(false);

        try {
          const queryParamsObj = new URLSearchParams();

          if (nuevasCategorias.length > 0 && !nuevasCategorias.includes(0)) {
            nuevasCategorias.forEach((categoriaId) => {
              queryParamsObj.append('categoria', categoriaId);
            });
          }

          if (nuevasMarcas.length > 0) {
            nuevasMarcas.forEach((marcaId) => {
              queryParamsObj.append('marca', marcaId);
            });
          }

          // Otras opciones: precioMin, precioMax, orden
          const orden = queryParams.get('orden');
          const precioMin = queryParams.get('precioMin');
          const precioMax = queryParams.get('precioMax');

          if (orden && ['asc', 'desc'].includes(orden)) {
            queryParamsObj.set('orden', orden);
          }

          if (precioMin && !isNaN(precioMin)) {
            queryParamsObj.set('precioMin', precioMin);
          }

          if (precioMax && !isNaN(precioMax)) {
            queryParamsObj.set('precioMax', precioMax);
          }

          const finalQuery = queryParamsObj.toString();
          const url = `${API_URL}productos?${finalQuery}`;

          

          const response = await fetch(url);
          if (!response.ok) throw new Error('Error al obtener los productos');
          const data = await response.json();

          // Procesar categoria_ids para asegurarse de que son números
          const productosProcesados = data.map(producto => ({
            ...producto,
            categoria_ids: producto.categoria_ids ? producto.categoria_ids.map(id => Number(id)) : []
          }));

          setProductos(productosProcesados);
        } catch (error) {
          console.error('Error al cargar productos:', error);
          setError(true);
        } finally {
          setLoading(false);
        }
      };

      fetchProductos();

    }, [location.search, API_URL]);

    // Abrir modal si se seleccionó un producto (/:id)
    useEffect(() => {
      if (id) {
        const productoId = parseInt(id, 10);
        const productoSeleccionado = productos.find((producto) => producto.id === productoId);

        if (productoSeleccionado) {
          abrirModal(productoSeleccionado);
          // No navegamos de vuelta aquí para mantener el :id en la URL
        } else {
          // Buscar el producto si no está en la lista actual
          const fetchProducto = async () => {
            try {
              const response = await fetch(`${API_URL}productos/${productoId}`);
              if (!response.ok) throw new Error('Error al obtener el producto');
              const data = await response.json();
              // Asegurar que categoria_ids son números
              const productoConCategorias = {
                ...data,
                categoria_ids: data.categoria_ids ? data.categoria_ids.map(id => Number(id)) : []
              };
              abrirModal(productoConCategorias);
              // No navegamos de vuelta aquí para mantener el :id en la URL
            } catch (error) {
              console.error('Error al cargar el producto seleccionado:', error);
            }
          };

          fetchProducto();
        }
      } else {
        // Cerrar el modal si no hay id en la URL
        cerrarModal();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, productos, API_URL, navigate]);

    // Verificar si el producto del modal sigue en la lista filtrada
    useEffect(() => {
      if (!loading && modalProducto) {
        const productoEnLista = productos.find(
          (producto) => producto.id === modalProducto.id
        );

        if (!productoEnLista) { 
          cerrarModal();
          alert('El producto seleccionado ya no está disponible bajo los filtros actuales.');
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productos, modalProducto, loading]);

    // Manejar cambios en los checkboxes
    const handleCheckboxChange = (e, tipo) => {
      const { value, checked } = e.target;
      const val = Number(value); // Convertir a número

      const queryParams = getQueryParams();

      if (tipo === 'categorias') {
        if (checked) {
          queryParams.append('categoria', val);
        } else {
          // Remove all 'categoria' params and re-add except the unchecked one
          const currentCategorias = queryParams.getAll('categoria').map(Number).filter((id) => id !== val);
          queryParams.delete('categoria');
          currentCategorias.forEach(id => queryParams.append('categoria', id));
        }
      } else if (tipo === 'marcas') {
        if (checked) {
          queryParams.append('marca', val);
        } else {
          // Remove all 'marca' params and re-add except the unchecked one
          const currentMarcas = queryParams.getAll('marca').map(Number).filter((id) => id !== val);
          queryParams.delete('marca');
          currentMarcas.forEach(id => queryParams.append('marca', id));
        }
      }

      setFiltrosExpandido((prev) => ({
        ...prev,
        [tipo]: queryParams.getAll(tipo).length > 0,
      }));

      navigate(`/productos?${queryParams.toString()}`, { replace: true });
    };

    // Manejar cambios en el orden
    const handleOrdenChange = (e) => {
      const orden = e.target.value;
      const queryParams = getQueryParams();

      if (orden === 'default') {
        queryParams.delete('orden');
      } else {
        queryParams.set('orden', orden);
      }

      navigate(`/productos?${queryParams.toString()}`, { replace: true });
    };

    // Abrir modal de producto
    const abrirModal = (producto) => setModalProducto(producto);

    // Cerrar modal de producto y navegar a /productos con los search params si 'id' estaba presente
    const cerrarModal = () => {
      setModalProducto(null);
      if (id) { // Solo navegar si 'id' estaba presente
        navigate(`/productos${location.search}`, { replace: true });
      }
    };

    // Alternar descripción expandida
    const toggleDescripcion = (id) => {
      setExpandido((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    };

    // Alternar expansión de filtros
    const toggleFiltros = (tipo) => {
      setFiltrosExpandido((prev) => ({
        ...prev,
        [tipo]: !prev[tipo],
      }));
    };

    // Resetear filtros
    const resetFiltros = () => {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('categoria');
      queryParams.delete('marca');
      queryParams.delete('orden');

      setFiltrosExpandido({
        categorias: false,
        marcas: false,
      });

      navigate(`/productos?${queryParams.toString()}`, { replace: true });
    };

    return (
      <div className="productos-clientes-container">
        <div className="productos-clientes-filtros">
          {/* Filtro de Orden */}
          <div className="productos-clientes-orden-precio">
            <label htmlFor="orden">Ordenar por:</label>
            <select name="orden" id="orden" value={getQueryParams().get('orden') || 'default'} onChange={handleOrdenChange}>
              <option value="default">Sin ordenar</option>
              <option value="asc">Precio Ascendente</option>
              <option value="desc">Precio Descendente</option>
            </select>
          </div>

          {/* Filtros de Categorías */}
          {categorias.length > 0 && (
            <div className={`productos-clientes-filtro-categorias ${filtrosExpandido.categorias ? 'expandido' : 'minimizado'}`}>
              <h3 onClick={() => toggleFiltros('categorias')}>
                Categorías
                <span className="expandir-icon">{filtrosExpandido.categorias ? '▲' : '▼'}</span>
              </h3>
              {filtrosExpandido.categorias && categorias.map(({ id, nombre, conteo }) => {
                const categoriaId = Number(id); // Asegurar que es un número

                return (
                  <label key={categoriaId} className="categoria-checkbox">
                    <input
                      type="checkbox"
                      value={categoriaId}
                      checked={getQueryParams().getAll('categoria').map(Number).includes(categoriaId)}
                      onChange={(e) => handleCheckboxChange(e, 'categorias')}
                    />
                    {nombre} ({conteo})
                  </label>
                );
              })}
            </div>
          )}

          {/* Filtros de Marcas */}
          {marcas.length > 0 && (
            <div className={`productos-clientes-filtro-marcas ${filtrosExpandido.marcas ? 'expandido' : 'minimizado'}`}>
              <h3 onClick={() => toggleFiltros('marcas')}>
                Marcas
                <span className="expandir-icon">{filtrosExpandido.marcas ? '▲' : '▼'}</span>
              </h3>
              {filtrosExpandido.marcas && marcas.map(({ id, nombre, conteo }) => (
                <label key={id} className="marca-checkbox">
                  <input
                    type="checkbox"
                    value={id}
                    checked={getQueryParams().getAll('marca').map(Number).includes(Number(id))}
                    onChange={(e) => handleCheckboxChange(e, 'marcas')}
                  />
                  {nombre} ({conteo})
                </label>
              ))}
            </div>
          )}

          {/* Opciones Adicionales */}
          <div className="productos-clientes-opciones-adicionales">
            <button className="reset-filtros-button" onClick={resetFiltros}>
              Resetear Filtros
            </button>
          </div>
        </div>

        <div className="productos-clientes-grid">
          {loading ? (
            <div className="productos-clientes-loading">Cargando productos...</div>
          ) : error ? (
            <div className="productos-clientes-error">Ocurrió un error al cargar los productos. Intenta nuevamente más tarde.</div>
          ) : productos.length === 0 ? (
            <div className="productos-clientes-no-results">No se encontraron productos.</div>
          ) : (
            productos.map((producto) => (
              <div
                key={producto.id}
                className="productos-clientes-producto-card"
                onClick={() => abrirModal(producto)}
              >
                {/* Media: mostrar solo la primera imagen por defecto */}
                {producto.medios && producto.medios.length > 0 ? (
                  <div className="producto-media">
                    {/* Primera Imagen */}
                    {producto.medios.filter(media => media.tipo === 'imagen').length > 0 && (
                      <img
                        key={`imagen-principal-${producto.id}`}
                        src={producto.medios.find(media => media.tipo === 'imagen').url.startsWith('http') ? 
                          producto.medios.find(media => media.tipo === 'imagen').url : 
                          `${API_URL}productos/imagenes/${producto.medios.find(media => media.tipo === 'imagen').url.split('/').pop()}`}
                        alt={`${producto.nombre} - Imagen principal`}
                        loading="lazy"
                        className="producto-imagen-principal"
                      />
                    )}

                    {/* Si no hay imágenes, mostrar el primer video como imagen */}
                    {producto.medios.filter(media => media.tipo === 'imagen').length === 0 && producto.medios.filter(media => media.tipo === 'video').length > 0 && (
                      <video
                        key={`video-principal-${producto.id}`}
                        src={producto.medios.find(media => media.tipo === 'video').url.startsWith('http') ? 
                          producto.medios.find(media => media.tipo === 'video').url : 
                          `${API_URL}productos/videos/${producto.medios.find(media => media.tipo === 'video').url.split('/').pop()}`}
                        loading="lazy"
                        className="producto-video-principal"
                        muted
                        loop
                        autoPlay
                        playsInline
                      />
                    )}
                  </div>
                ) : (
                  <div className="producto-sin-media">
                    <img src="/images/placeholder.jpg" alt="Sin imagen disponible" loading="lazy" />
                  </div>
                )}

                {/* Información del Producto */}
                <h3 className="productos-clientes-producto-nombre">{producto.nombre}</h3>
                <p className="productos-clientes-producto-precio">${producto.precio}</p>
                
                {/* Mostrar Categorías Asociadas */}
                {producto.categoria_ids && producto.categoria_ids.length > 0 && (
                  <div className="productos-clientes-producto-categorias">
                  
                  </div>
                )}

                <p
                  className={`productos-clientes-producto-descripcion ${
                    expandido[producto.id] ? 'expandido' : ''
                  }`}
                >
                  {producto.descripcion}
                </p>
                <button
                  className="productos-clientes-expandir-descripcion"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDescripcion(producto.id);
                  }}
                >
                  {expandido[producto.id] ? 'Ver menos' : 'Ver más'}
                </button>
                <p className="productos-clientes-precio-nota">
                  * Los precios pueden variar. Consulte en la tienda.
                </p>
              </div>
            ))
          )}
        </div>

        {modalProducto && <ModalProducto producto={modalProducto} cerrarModal={cerrarModal} />}
      </div>
    );

  };

  export default ProductosClientes;
