// src/components/Servicios/reservasService.js

import { apiFetch } from './api';

/**
 * Obtener todas las reservas de un puesto para una fecha específica.
 */
export const fetchReservas = async (puestoId, fecha) => {
  try {

    const reservas = await apiFetch(`puestos/${puestoId}/reservas?fecha=${fecha}`, {
      method: 'GET',
    });

    return reservas;
  } catch (error) {
    console.error('reservasService: Error al obtener las reservas:', error);
    throw error;
  }
};

/**
 * Obtener reservas de un mes específico (rango de fechas).
 */
export const fetchReservasMes = async (puestoId, startDate, endDate) => {
  try {
    const start = startDate.toISOString().split('T')[0];
    const end = endDate.toISOString().split('T')[0];

    const reservas = await apiFetch(`puestos/${puestoId}/reservas?startDate=${start}&endDate=${end}`, {
      method: 'GET',
    });

    return reservas;
  } catch (error) {
    console.error('reservasService: Error al obtener las reservas del mes:', error);
    throw error;
  }
};

/**
 * Crear una nueva reserva.
 */
export const createReserva = async (puestoId, reservaData) => {
  try {

    const reservaCreada = await apiFetch(`puestos/${puestoId}/reservas`, {
      method: 'POST',
      body: JSON.stringify(reservaData),
    });

    return reservaCreada;
  } catch (error) {
    console.error('reservasService: Error al crear la reserva:', error);
    throw error;
  }
};

/**
 * Actualizar una reserva existente.
 */
export const updateReserva = async (puestoId, reservaId, reservaData) => {
  try {

    const reservaActualizada = await apiFetch(`puestos/${puestoId}/reservas/${reservaId}`, {
      method: 'PUT',
      body: JSON.stringify(reservaData),
    });

    return reservaActualizada;
  } catch (error) {
    console.error('reservasService: Error al actualizar la reserva:', error);
    throw error;
  }
};

/**
 * Eliminar una reserva.
 */
export const deleteReserva = async (puestoId, reservaId) => {
  try {

    const resultado = await apiFetch(`puestos/${puestoId}/reservas/${reservaId}`, {
      method: 'DELETE',
    });

    return resultado;
  } catch (error) {
    console.error('reservasService: Error al eliminar la reserva:', error);
    throw error;
  }
};

/**
 * Marcar una reserva como completada.
 */
export const marcarReservaCompletada = async (puestoId, reservaId) => {
  try {

    const reservaActualizada = await apiFetch(`puestos/${puestoId}/reservas/${reservaId}/estado`, {
      method: 'PATCH',
      body: JSON.stringify({ estado: 'completada' }),
    });

    return reservaActualizada;
  } catch (error) {
    console.error('reservasService: Error al marcar la reserva como completada:', error);
    throw error;
  }
};

/**
 * Actualizar el estado de una reserva.
 */
export const updateEstadoReserva = async (puestoId, reservaId, estado) => {
  try {

    const reservaActualizada = await apiFetch(`puestos/${puestoId}/reservas/${reservaId}/estado`, {
      method: 'PATCH',
      body: JSON.stringify({ estado }),
    });

    return reservaActualizada;
  } catch (error) {
    console.error('reservasService: Error al actualizar el estado de la reserva:', error);
    throw error;
  }
};

/**
 * Obtener todos los turnos de un puesto (con empleadoNombre si el backend hace el JOIN).
 */
export const fetchTurnos = async (puestoId) => {
  try {

    const turnos = await apiFetch(`puestos/${puestoId}/turnos`, {
      method: 'GET',
    });

    return turnos;
  } catch (error) {
    console.error('reservasService: Error al obtener los turnos:', error);
    throw error;
  }
};

/**
 * Abrir un nuevo turno para un puesto.
 */
export const abrirTurno = async (puestoId, data) => {
  try {

    const turnoCreado = await apiFetch(`puestos/${puestoId}/turnos/abrir`, {
      method: 'POST',
      body: JSON.stringify(data),
    });

    return turnoCreado;
  } catch (error) {
    console.error('reservasService: Error al abrir el turno:', error);
    throw error;
  }
};

/**
 * Cerrar un turno existente.
 */
export const cerrarTurno = async (puestoId, turnoId, data = {}) => { // Añadido parámetro data con valor por defecto
  try {

    const turnoCerrado = await apiFetch(`puestos/${puestoId}/turnos/${turnoId}/cerrar`, {
      method: 'POST',
      body: JSON.stringify(data), // Incluir el cuerpo de la solicitud
    });

    return turnoCerrado;
  } catch (error) {
    console.error('reservasService: Error al cerrar el turno:', error);
    throw error;
  }
};

/**
 * Cambiar el empleado asignado a un turno abierto.
 */
export const cambiarEmpleado = async (puestoId, turnoId, data) => {
  try {

    const turnoActualizado = await apiFetch(`puestos/${puestoId}/turnos/${turnoId}/cambiar-empleado`, {
      method: 'POST',
      body: JSON.stringify(data),
    });

    return turnoActualizado;
  } catch (error) {
    console.error('reservasService: Error al cambiar empleado en el turno:', error);
    throw error;
  }
};

/**
 * Obtener reporte de servicios de un puesto.
 */
export const fetchReporteServicios = async (puestoId, inicio, fin) => {
  try {
    const endpoint = `puestos/${puestoId}/reporte?inicio=${inicio}&fin=${fin}`;

    const reporte = await apiFetch(endpoint, { method: 'GET' });

    return reporte;
  } catch (error) {
    console.error('reservasService: Error al obtener el reporte de servicios:', error);
    throw error;
  }
};
