// src/hooks/useReservas.js

import { useState, useCallback } from 'react';
import {
  fetchReservas,
  fetchReservasMes,
  createReserva,
  updateReserva,
  deleteReserva,
  marcarReservaCompletada,
  fetchReporteServicios,
  updateEstadoReserva, // Importación correcta
} from '../components/Servicios/reservasService';

export function useReservas(puesto) {
  const [reservas, setReservas] = useState([]);
  const [reservasMes, setReservasMes] = useState([]);

  const getFormattedDate = useCallback((date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      console.error('Fecha inválida:', date);
      return '';
    }
    return date.toISOString().split('T')[0];
  }, []);

  const fetchReservasData = useCallback(
    async (selectedDate) => {
      const fechaSeleccionada = getFormattedDate(selectedDate);
      if (!fechaSeleccionada) {
        setReservas([]);
        return;
      }
      try {
        const data = await fetchReservas(puesto.id, fechaSeleccionada);
        setReservas(data);
      } catch (error) {
        console.error('Error al obtener las reservas:', error);
        setReservas([]);
      }
    },
    [puesto.id, getFormattedDate]
  );

  const fetchReservasMesData = useCallback(
    async (activeStartDate) => {
      const startDate = new Date(
        activeStartDate.getFullYear(),
        activeStartDate.getMonth(),
        1
      );
      const endDate = new Date(
        activeStartDate.getFullYear(),
        activeStartDate.getMonth() + 1,
        0
      );
      try {
        const data = await fetchReservasMes(puesto.id, startDate, endDate);
        setReservasMes(data);
      } catch (error) {
        console.error('Error al obtener las reservas del mes:', error);
        setReservasMes([]);
      }
    },
    [puesto.id]
  );

  const handleCrearOActualizarReserva = useCallback(
    async (reservaEnEdicion, reservaData) => {
      if (reservaEnEdicion) {
        // Actualizar reserva existente
        const reservaActualizada = await updateReserva(puesto.id, reservaEnEdicion.id, reservaData);
        // Actualizar el estado local
        setReservas((prevReservas) =>
          prevReservas.map((reserva) =>
            reserva.id === reservaActualizada.id ? reservaActualizada : reserva
          )
        );
        return reservaActualizada;
      } else {
        // Crear nueva reserva
        const reservaCreada = await createReserva(puesto.id, reservaData);
        // Actualizar el estado local
        setReservas((prevReservas) => [...prevReservas, reservaCreada]);
        return reservaCreada;
      }
    },
    [puesto.id, setReservas]
  );

  const handleEliminarReserva = useCallback(
    async (reservaId) => {
      await deleteReserva(puesto.id, reservaId);
      const hoy = new Date();
      await fetchReservasData(hoy);
      await fetchReservasMesData(hoy);
    },
    [puesto.id, fetchReservasData, fetchReservasMesData]
  );

  const handleMarcarCompletada = useCallback(
    async (reservaId) => {
      await marcarReservaCompletada(puesto.id, reservaId);
      const hoy = new Date();
      await fetchReservasData(hoy);
      await fetchReservasMesData(hoy);
    },
    [puesto.id, fetchReservasData, fetchReservasMesData]
  );

  const handleActualizarEstadoReserva = useCallback(
    async (reservaId, estado) => {
      try {
        const reservaActualizada = await updateEstadoReserva(puesto.id, reservaId, estado);
        // Actualizar el estado local
        setReservas((prevReservas) =>
          prevReservas.map((reserva) =>
            reserva.id === reservaActualizada.id ? reservaActualizada : reserva
          )
        );
        return reservaActualizada;
      } catch (error) {
        console.error('Error al actualizar el estado de la reserva:', error);
        throw error;
      }
    },
    [puesto.id, setReservas]
  );

  const parseTimeToDate = useCallback((dateString, timeString) => {
    if (typeof timeString !== 'string') {
      console.error('Invalid timeString:', timeString);
      return new Date(dateString);
    }

    const [hours, minutes] = timeString.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes)) {
      console.error('Invalid time format:', timeString);
      return new Date(dateString);
    }

    const date = new Date(dateString);
    date.setHours(hours, minutes, 0, 0);
    return date;
  }, []);

  return {
    reservas,
    reservasMes,
    fetchReservasData,
    fetchReservasMesData,
    handleCrearOActualizarReserva,
    handleEliminarReserva,
    handleMarcarCompletada,
    handleActualizarEstadoReserva, 
    parseTimeToDate,
    getFormattedDate,
  };
}
