import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="custom-footer">
      <div className="footer-content">
        {/* Información de Contacto */}
        <div className="footer-section">
          <h4>Contacto</h4>
          <p>
            <i className="fas fa-map-marker-alt"></i>
            <a
              href="https://www.google.com/maps/place/Rafael+Casanova+222,+Santa+Cruz"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rafael Casanova 222, Local 4, Santa Cruz, 6ta Región, Chile
            </a>
          </p>
          <p>
            <i className="fas fa-phone-alt"></i>
            <a href="tel:+56977286466">+56 9 7728 6466</a>
          </p>
          <p>
            <i className="fas fa-envelope"></i>
            <a href="mailto:maria.luz.galvez.contreras.galvez@gmail.com">
              maria.luz.galvez.contreras.galvez@gmail.com
            </a>
          </p>
        </div>

        {/* Horarios */}
        <div className="footer-section">
          <h4>Horarios</h4>
          <p>Lun-Vie: 10:00 - 13:00 / 13:00 - 18:30</p>
          <p>Sáb: 10:00 - 14:00</p>
        </div>

        {/* Redes Sociales */}
        <div className="footer-section">
          <h4>Síguenos</h4>
          <div className="social-links">
            <a
              href="https://www.instagram.com"
              className="social-icon instagram"
              aria-label="Instagram"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://wa.me/56977286466"
              className="social-icon whatsapp"
              aria-label="WhatsApp"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </div>

      {/* Minimal Footer */}
      <div className="footer-minimal">
        &copy; {new Date().getFullYear()} Maryland Beauty & Care. Todos los derechos reservados.
      </div>
    </footer>
  );
};

export default Footer;
