// src/AppRoutes.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home/Home';
import UserProfile from './components/UserProfile/UserProfile';
import Dashboard from './components/Dashboard/Dashboard';
import EditarPerfil from './components/EditarPerfil/EditarPerfil';
import { useAuth } from './contexts/AuthContext';
import ResetPasswordForm from './components/ResetPasswordForm/ResetPasswordForm';
import ForgotPasswordForm from './components/ForgotPasswordForm/ForgotPasswordForm';
import ArrendadorDashboard from './components/Pages/ArrendadorDashboard/ArrendadorDashboard';
import ProductosClientes from './components/Pages/Productos/ProductosClientes';
// Componentes de inventario
import InventarioLayout from './components/ventas/InventarioLayout';
import InventarioDashboard from './components/ventas/InventarioDashboard';
import ProductosAdmin from './components/ventas/ProductosAdmin';
import HistorialMovimientos from './components/ventas/HistorialMovimientos';
import Transferencias from './components/ventas/Transferencias';
import GestionarDestacados from './components/ventas/GestionarDestacados/GestionarDestacados'; // Importar el nuevo componente

const AppRoutes = () => {
  const { isAuthenticated, user } = useAuth(); // Usamos el usuario del contexto

  // Muestra un indicador de carga mientras se verifica la autenticación
  if (isAuthenticated === null) {
    return <div>Cargando...</div>; // O usa un spinner o un componente de carga
  }

  return (
    <Routes>
      {/* Rutas públicas */}
      <Route path="/" element={<Home />} />
      {/* Ruta de productos con parámetro opcional :id para abrir el modal */}
      <Route path="/productos/:id?" element={<ProductosClientes />} />
      <Route path="/forgot-password" element={<ForgotPasswordForm />} />
      <Route path="/reset-password/:token" element={<ResetPasswordForm />} />

      {/* Rutas protegidas */}
      {isAuthenticated ? (
        <>
          <Route path="/perfil" element={<UserProfile />} />
          <Route path="/editar-perfil" element={<EditarPerfil />} />

          {/* Ruta de dashboard con SidebarIzquierdo */}
          <Route
            path="/dashboard"
            element={
              user && user.tipo_usuario === 'Arrendador' ? (
                <ArrendadorDashboard />
              ) : (
                <Dashboard />
              )
            }
          />

          {/* Rutas de inventario con InventarioLayout */}
          <Route path="/inventario/*" element={<InventarioLayout />}>
            <Route index element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<InventarioDashboard />} />
            <Route path="productos" element={<ProductosAdmin />} />
            <Route path="historial" element={<HistorialMovimientos />} />
            <Route path="transferencias" element={<Transferencias />} />
            {/* Nueva ruta para gestionar destacados */}
            <Route path="destacados" element={<GestionarDestacados />} />
          </Route>
        </>
      ) : (
        // Si no está autenticado, redirigimos las rutas protegidas a la página de inicio
        <>
          <Route path="/perfil" element={<Navigate to="/" replace />} />
          <Route path="/editar-perfil" element={<Navigate to="/" replace />} />
          <Route path="/dashboard" element={<Navigate to="/" replace />} />
          <Route path="/inventario/*" element={<Navigate to="/" replace />} />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
