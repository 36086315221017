import { useState, useCallback } from 'react';
import { fetchServicios } from '../components/Servicios/serviciosService';

export function useServicios() {
  const [servicios, setServicios] = useState([]);
  const [loadingServicios, setLoadingServicios] = useState(false);
  const [selectedServicio, setSelectedServicio] = useState(null);

  const loadServicios = useCallback(async () => {
    if (loadingServicios || servicios.length > 0) return;
    setLoadingServicios(true);
    try {
      const data = await fetchServicios();
      setServicios(data);
    } catch (error) {
      console.error('Error al cargar los servicios:', error);
    } finally {
      setLoadingServicios(false);
    }
  }, [loadingServicios, servicios.length]);

  return {
    servicios,
    selectedServicio,
    setSelectedServicio,
    loadServicios,
    loadingServicios
  };
}
