// src/components/DashboardContent/DashboardContent.js

import React, { useState, useEffect, useCallback } from 'react';
import CalendarioReservas from '../CalendarioReservas/CalendarioReservas';
import AgregarPuesto from '../AgregarPuesto/AgregarPuesto';
import {
  fetchPuestos,
  deletePuesto,
  updatePuesto,
  fetchEstadisticas,
} from '../Servicios/puestosService';
import { fetchTurnos } from '../Servicios/reservasService';
import { toast } from 'react-toastify';
import './DashboardContent.css';

const DashboardContent = () => {
  const [puestos, setPuestos] = useState([]);
  const [error, setError] = useState('');

  // Carga de puestos + estadísticas + persona a cargo
  const loadPuestos = useCallback(async () => {
    try {
    
      const data = await fetchPuestos();
     

      // Obtener estadísticas para cada puesto
      const puestosConEstadisticas = await Promise.all(
        data.map(async (puesto) => {
          try {
            const estadisticas = await fetchEstadisticas(puesto.id);
           
            return { ...puesto, estadisticas };
          } catch (e) {
            console.error(
              `DashboardContent: Error al obtener estadísticas para el puesto ID ${puesto.id}:`,
              e
            );
            return { ...puesto, estadisticas: [] };
          }
        })
      );

      // Obtener turno abierto para mostrar la persona a cargo
      const puestosConTurno = await Promise.all(
        puestosConEstadisticas.map(async (puesto) => {
          try {
            const turnos = await fetchTurnos(puesto.id);
           
            // Buscar turno en estado 'abierto'
            const turnoAbierto = turnos.find((t) => t.estado === 'abierto');

            // Verificamos si el backend retornó "empleadoNombre"
            let personaACargo = 'No hay empleado asignado actualmente';
            if (turnoAbierto && turnoAbierto.empleadoNombre) {
              personaACargo = turnoAbierto.empleadoNombre;
            }

            return {
              ...puesto,
              personaACargo,
            };
          } catch (err) {
            console.error(
              `DashboardContent: Error al obtener los turnos para el puesto ID ${puesto.id}:`,
              err
            );
            return {
              ...puesto,
              personaACargo: 'No se pudo obtener el empleado a cargo'
            };
          }
        })
      );

    
      setPuestos(puestosConTurno);
    } catch (error) {
      setError('Error al obtener los puestos.');
      console.error('DashboardContent: Error al obtener los puestos:', error);
      toast.error('Error al obtener los puestos.');
    }
  }, []);

  // Cargar al montar
  useEffect(() => {
    loadPuestos();
  }, [loadPuestos]);

  // Maneja agregar puesto
  const handlePuestoAdded = useCallback((nuevoPuesto) => {
    setPuestos((prev) => [
      ...prev,
      {
        ...nuevoPuesto,
        personaACargo: 'No hay empleado asignado actualmente',
        estadisticas: []
      }
    ]);
    toast.success('Puesto agregado exitosamente.');
  }, []);

  // Maneja la adición de una nueva reserva
  const handleReservaAdded = useCallback((nuevaReserva) => {
  
    setPuestos((prevPuestos) =>
      prevPuestos.map((puesto) =>
        puesto.id === nuevaReserva.puestoId
          ? { ...puesto, reservas: [...(puesto.reservas || []), nuevaReserva] }
          : puesto
      )
    );
    toast.success('Reserva agregada exitosamente.');
  }, []);

  // Eliminar puesto
  const handleEliminarPuesto = useCallback(async (id) => {
    try {
   
      await deletePuesto(id);
      setPuestos((prev) => prev.filter((p) => p.id !== id));
      toast.success('Puesto eliminado exitosamente.');
    } catch (error) {
      console.error('DashboardContent: Error al eliminar el puesto:', error);
      toast.error('No se pudo eliminar el puesto.');
    }
  }, []);

  // Editar puesto
  const handleEditarPuesto = useCallback(async (id, updatedData) => {
    try {
   
      await updatePuesto(id, updatedData);
      setPuestos((prev) =>
        prev.map((p) => (p.id === id ? { ...p, ...updatedData } : p))
      );
      toast.success('Puesto actualizado exitosamente.');
    } catch (error) {
      console.error('DashboardContent: Error al actualizar el puesto:', error);
      toast.error('No se pudo actualizar el puesto.');
    }
  }, []);

  // Cuando cambia un turno (se abre/cierra o cambia empleado)
  const handleTurnoChanged = useCallback(
    (puestoId) => {
    
      loadPuestos();
      toast.info('Datos de turno actualizados.');
    },
    [loadPuestos]
  );

  return (
    <div className="dashboard-content-inner">
      {error && <p className="error-text">{error}</p>}

      <div className="gestion-puestos">
        <h2>Gestión de Puestos</h2>
        {puestos.length > 0 ? (
          puestos.map((puesto) => (
            <div key={puesto.id} className="puesto-card">
              <div className="puesto-info">
                <div className="puesto-details">
                  <h3>{puesto.nombre}</h3>
                  <p>
                    <strong>Persona a cargo (Turno Abierto):</strong>{' '}
                    {puesto.personaACargo}
                  </p>
                  <p>
                    <strong>Descripción:</strong> {puesto.descripcion}
                  </p>
                </div>

                <div className="puesto-estadisticas">
                  <h4>Estadísticas:</h4>
                  {puesto.estadisticas && puesto.estadisticas.length > 0 ? (
                    puesto.estadisticas.map((stat, index) => (
                      <div key={index}>
                        <strong>{stat.estado}:</strong> {stat.cantidad} reservas, Ganancias: $
                        {parseFloat(stat.total_ganancias).toLocaleString()}
                      </div>
                    ))
                  ) : (
                    <p>No hay estadísticas disponibles.</p>
                  )}
                </div>
              </div>

              {/* CalendarioReservas maneja reservas y turnos en tiempo real */}
              <CalendarioReservas
                puesto={puesto}
                onReservaAdded={handleReservaAdded} // Usar handleReservaAdded
                onTurnoChanged={handleTurnoChanged}
              />

              <div className="puesto-buttons">
                <button
                  className="btn btn-editar"
                  onClick={() =>
                    handleEditarPuesto(puesto.id, {
                      nombre: 'Nuevo Nombre',
                      descripcion: 'Nueva Descripción'
                    })
                  }
                >
                  Editar
                </button>
                <button
                  className="btn btn-eliminar"
                  onClick={() => handleEliminarPuesto(puesto.id)}
                >
                  Eliminar
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No hay puestos registrados.</p>
        )}
      </div>

      <div className="agregar-puesto-container">
        <h2>Agregar Nuevo Puesto</h2>
        <AgregarPuesto onPuestoAdded={handlePuestoAdded} />
      </div>
    </div>
  );
};

export default DashboardContent;
